import { ToastContainer } from 'react-toastify';
import OpenIncidents from './components/OpenIncidents/OpenIncidents';
import Stats from './components/Stats';
import './styles.scss';
import { useEffect, useState } from 'react';
import Loading from 'components/Common/Loading';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import { Button, Modal } from '@trimbleinc/modus-react-bootstrap';
import Configurations from './components/Configurations';

function Dashboard() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStatsLoaded, setIsStatsLoaded] = useState<boolean>(false);
  const [isOpenIncidentsLoaded, setIsOpenIncidentsLoaded] =
    useState<boolean>(false);
  const [isPlannedMaintenceLoaded, setIsPlannedMaintenceLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    // Set loading to false only when both child components have loaded
    if (isStatsLoaded && isOpenIncidentsLoaded && isPlannedMaintenceLoaded) {
      setIsLoading(false);
    }
  }, [isStatsLoaded, isOpenIncidentsLoaded, isPlannedMaintenceLoaded]);

  const handleStatsLoadSuccess = () => {
    setIsStatsLoaded(true);
  };

  const handleOpenIncidentsLoadSuccess = () => {
    setIsOpenIncidentsLoaded(true);
  };

  const handlePlannedMaintenceLoadSuccess = () => {
    setIsPlannedMaintenceLoaded(true);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <div className="main-tab-container">
      {isLoading && <Loading />}
      <ToastContainer />

      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <h1 className="heading">Dashboard</h1>
        <span className="config-button-position">
          <Button
            variant="primary"
            size="lg"
            style={{ borderRadius: '35px'}}
            onClick={handleShow}
            disabled={isNotAdmin()}>
            <i className="modus-icons material-icons left-icon" aria-hidden="true">
              edit
            </i>
            Configurations
          </Button>
        </span>
        <Stats onLoadSuccess={handleStatsLoadSuccess} />
        <OpenIncidents
          onIncidentsLoadSuccess={handleOpenIncidentsLoadSuccess}
          onMaintenanceLoadSuccess={handlePlannedMaintenceLoadSuccess}
        />
        <Modal show={show} centered onHide={handleClose} size='xl'>
        <Modal.Header
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          closeButton
        >
          <Modal.Title>Configurations</Modal.Title>
        </Modal.Header>

        <Modal.Body className='configuration-body'>
          <Configurations />
        </Modal.Body>
      </Modal>
      </div>
    </div>
  );
}

export default Dashboard;
