export const INCIDENTS_ID_CONSTANTS = {
  input: 'Incident ID',
  placeholder: 'Incident ID',
  error: 'Please enter a valid Incident ID.',
};

export interface IIncidentIdInputProps {
  onIdChange?: (id: string) => void;
  isError: boolean;
  value?: string;
  isDisabled: boolean;
  label?: string;
};
