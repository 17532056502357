import exp from "constants";
import Environment from "./../config";
export const apiURL = Environment.userManagementUrl;
export const incidentURL = Environment.incidentManagementUrl;
export const serviceURL = Environment.serviceManagementUrl;
export const groupURL = Environment.groupManagementUrl;
export const statusCastBaseUrl = Environment.statusCastBaseUrl;
export const statusCastToken = Environment.statusCastToken;

const serviceUrls = {
    usersURL: `${apiURL}`,
    incidentsURL: `${incidentURL}`,
    servicesURL: `${serviceURL}`,
    groupsURL: `${groupURL}`,
    statusCastURL: `${statusCastBaseUrl}`,
};

export const serviceTokens = {
    statusCastToken: `${statusCastToken}`,
};

export default serviceUrls;