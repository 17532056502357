import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { fetchUnresolvedIncidents } from '../../../../../../services/incidentManagement.service';
import Loading from '../../../../../../components/Common/Loading';
import IncidentSearchBox from '../../../../../../components/Common/IncidentSearchBox';
import PaginationButton from '../../../../../../components/Common/PaginationButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from '@trimbleinc/modus-react-bootstrap';
import AccordionInput from '../../../../../../components/Common/AccordionInput';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import { OPEN_INCIDENTS_CONSTANTS } from '../../OpenIncidents.constants';
import { isNotUser } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function ListIncidents({ onIncidentsLoadSuccess }: any) {
  const [incidents, setIncidents] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [incidentId, setIncidentId] = useState('');
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedService, setSelectedService] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const latestRequestId = useRef(0);

  const handleServiceSelect = (product: string, service: string) => {
    if (product !== selectedProduct && service !== selectedService) {
      setIsLoaded(false);
    }
    setSelectedProduct(product);
    setSelectedService(service);
  };

  const fetchUnResolvedIncident = async (requestId: any, page = 0) => {
    try {
      const incidentsResponse = await fetchUnresolvedIncidents(
        page,
        incidentId,
        selectedService,
        selectedProduct
      );

      if (incidentsResponse.status == 200) {
        if (requestId === latestRequestId.current) {
          setIsLoaded(true);
          onIncidentsLoadSuccess();
          setIncidents(incidentsResponse.data.data);
          setHasNext(incidentsResponse.data.hasNextPage);
        }
      }
      if (incidentsResponse.status == 400 || incidentsResponse.status == 500) {
        toast.error(incidentsResponse?.data ?? 'Failed to fetch Incidents');
        logEvent('GET_UNRESOLVED_INCIDENTS', 'EXCEPTION', {
          incidentID: incidentId,
          service: selectedService,
          product: selectedProduct,
          actions: 'Retrieve',
          resource: 'Incidents',
          error: incidentsResponse?.data
            ? `"${incidentsResponse?.data}"`
            : 'Failed to fetch Incidents',
        });
      }
      if (incidentsResponse.status == 401) {
        toast.error('Unauthorized');
        clearSessionAndRedirect();
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    const requestId = ++latestRequestId.current;
    fetchUnResolvedIncident(requestId, currentPage);
  }, [incidentId, currentPage, selectedService]);

  const handleIncidentIdChange = (newIncidentId: any) => {
    if (incidentId !== newIncidentId) {
      setIsLoaded(false);
      setIncidentId(newIncidentId);
      setCurrentPage(0);
    }
  };

  const handleNext = () => {
    setIsLoaded(false);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setIsLoaded(false);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleViewIncidents = (incidentId: string, incidents: any) => {
    let baseURL = location.pathname.split('/')[1];

    navigate(`${RouterPath.PRIVATE_ROUTE.incidentManagement}/` + incidentId, {
      state: {
        baseUrl: {
          url: baseURL,
        },
        incidentDetails: {
          incidentId: incidents.incidentId,
          type: incidents.type,
          incidentState: incidents.incidentState,
          product: incidents.product,
          service: incidents.service,
          startTimeInUTC: incidents.startTimeInUTC,
          endTimeInUTC: incidents.endTimeInUTC,
          escalationLevel: incidents.escalationLevel,
          isFalseAlarm: incidents.isFalseAlarm,
          hourlyUpdateMailCount: incidents.hourlyUpdateMailCount,
        },
      },
    });
  };

  return (
    <div>
      <div className='grid-container-open-incidents'>
        <div className='grid-item-open-incidents'>
          <AccordionInput
            onServiceSelect={handleServiceSelect}
            isError={false}
            isDisabled={false}
            isSearchFilter={true}
          />
        </div>
        <div className='search-box-incident grid-item-open-incidents'>
          <IncidentSearchBox
            inputValue={incidentId}
            onIncidentIdChange={handleIncidentIdChange}
          />
        </div>
        <div className='grid-item-open-incidents'>
          <PaginationButton
            currentPage={currentPage}
            hasNext={hasNext}
            onPrevious={handlePrevious}
            onNext={handleNext}
          />
        </div>
      </div>
      <div className='list-open-container'>
        {isLoaded ? (
          incidents.length > 0 ? (
            <div>
              {incidents.map((incident, index) => (
                <div key={incident.incidentId}>
                  <div className='d-flex flex-row justify-content-between incindentCard'>
                    <div className='d-flex flex-row'>
                      <span className='info-content'>
                        {incident.type === 'PlannedMaintenance' ? (
                          <i
                            className='modus-icons'
                            aria-hidden='true'
                            style={{ color: '#ffe900' }}
                          >
                            clock
                          </i>
                        ) : incident.type === 'PartialOutage' ? (
                          <i
                            className='modus-icons'
                            aria-hidden='true'
                            style={{ color: '#ff9f00' }}
                          >
                            info
                          </i>
                        ) : (
                          <i
                            className='modus-icons'
                            aria-hidden='true'
                            style={{ color: '#e51c23' }}
                          >
                            cancel_circle
                          </i>
                        )}
                      </span>

                      <div className='incident-info'>
                        <span>
                          <strong>Incident ID:</strong> {incident.incidentId}
                        </span>{' '}
                        |
                        <span>
                          <strong> Start Time:</strong>{' '}
                          {incident.startTimeInUTC} UTC
                        </span>{' '}
                        |
                        <span>
                          <strong> Incident Type:</strong> {incident.type}
                        </span>{' '}
                        |
                        <span>
                          <strong> Product:</strong> {incident.product}
                        </span>{' '}
                        |
                        <span>
                          <strong>Service:</strong> {incident.service}
                        </span>
                      </div>
                    </div>
                    <div className='edit-button'>
                      <OverlayTrigger
                        key='overlay1'
                        placement='top'
                        overlay={<Tooltip id='btntooltip'>Edit</Tooltip>}
                      >
                        <i
                          className='modus-icons'
                          aria-hidden='true'
                          onClick={
                            isNotUser()
                              ? () => {
                                  handleViewIncidents(
                                    incident.incidentId,
                                    incident
                                  );
                                }
                              : undefined
                          }
                          style={{ color: isNotUser() ? '#0063a3' : '#d0d0d7' }}
                        >
                          file_edit
                        </i>
                      </OverlayTrigger>
                    </div>
                  </div>
                  {incidents.length - 1 !== index && (
                    <hr style={{ backgroundColor: '#AEAEAE' }} />
                  )}{' '}
                </div>
              ))}
            </div>
          ) : (
            <div className='empty-incidents'>
              <p>
                There are no ongoing incidents, Click{' '}
                <a href={RouterPath.PRIVATE_ROUTE.createIncident}>here</a> to
                get started.
              </p>
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default ListIncidents;
