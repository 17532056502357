import { getItemFromLocalStorage } from '../utils/LocalStorage.utils';
import { fetchReq } from './http.service';
import serviceUrls from './serviceUrls';

const { servicesURL } = serviceUrls;

export const fetchAllServices = async () => {
    const url = `${servicesURL}/api/getallservices?triggers=true`;
    return await fetchReq(url, {
        method: 'GET',
        headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
        },
    });
};

export const fetchAllServicesWithStatuscastId = async () => {
    const url = `${servicesURL}/api/getallservices`;
    return await fetchReq(url, {
        method: 'GET',
        headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
        },
    });
};

export const fetchServiceDetails = async (product: string, service: string) => {
    const url = `${servicesURL}/api/services?product=${product}&service=${service}`;
    return await fetchReq(url, {
        method: 'GET',
        headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
        },
    });
};

export const updateServiceDetails = async (data: any) => {
    const url = `${servicesURL}/api/services?isUI=true`;
    return await fetchReq(url, {
        method: 'PUT',
        headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
};

export const deleteService = async (product:string, service:string) => {
    const url = `${servicesURL}/api/services?product=${product}&service=${service}&removeSubscribers=true`;
    return await fetchReq(url, {
        method: 'DELETE',
        headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
        },
    });
}

export const createService = async (data: any) => {
    const url = `${servicesURL}/api/services`;
    return await fetchReq(url, {
        method: 'POST',
        headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`
        },
        body: JSON.stringify(data),
    });
};

export const fetchServiceMessage = async (product: string, service: string, type: string) => {
  const url = `${servicesURL}/api/services?product=${product}&service=${service}&getType=${type}`;
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

