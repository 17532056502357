export const transformMessageType = (type:any) => {
    switch (type) {
        case 'New_Outage':
            return 'New Outage';
        case 'Updating_Outage_1':
            return 'Outage First Update';
        case 'Updating_Outage_2':
            return 'Outage Second Update';
        case 'Resolved_Outage':
            return 'Resolved Outage';
        case 'New_PlannedMaintenance':
            return 'New Planned Maintenance';
        case 'Updating_PlannedMaintenance_1':
            return 'Planned Maintenance Update/Reminder';
        case 'Resolved_PlannedMaintenance':
            return 'Resolved Planned Maintenance';
        case 'New_PartialOutage':
            return 'New Partial Outage/Degradation';
        case 'Updating_PartialOutage_1':
            return 'Partial Outage/Degradation First Update';
        case 'Updating_PartialOutage_2':
            return 'Partial Outage/Degradation Second Update';
        case 'Resolved_PartialOutage':
            return 'Resolved Partial Outage/Degradation';
        case 'Transition_Outage':
            return 'Transition to Outage';
        case 'Transition_PartialOutage':
            return 'Transition to Partial Outage/Degradation';
        case 'Disater_Recovery':
            return 'Disaster Recovery';
        case 'Outage_ESC1':
            return 'Outage First Escalation';
        case 'Outage_ESC2':
            return 'Outage Second Escalation';
        case 'PartialOutage_ESC1':
            return 'Partial Outage/Degradation First Escalation';
        case 'PartialOutage_ESC2':
            return 'Partial Outage/Degradation Second Escalation';
        case 'falseAlarm':
            return 'False Alarm';
        default:
            return type;
    }
};

export const domoColumns = {
    newOutage: "New_Outage",
    outageFirstUpdate: "Updating_Outage_1",
    outageSecondUpdate: "Updating_Outage_2",
    resolvedOutage: "Resolved_Outage",
    newPlannedMaintenance: "New_PlannedMaintenance",
    plannedMaintenanceUpdate: "Updating_PlannedMaintenance_1",
    resolvedPlannedMaintenance: "Resolved_PlannedMaintenance",
    newPartialOutage: "New_PartialOutage",
    partialOutageFirstUpdate: "Updating_PartialOutage_1",
    partialOutageSecondUpdate: "Updating_PartialOutage_2",
    resolvedPartialOutage: "Resolved_PartialOutage",
    transitionToOutage: "Transition_Outage",
    transitionToPartialOutage: "Transition_PartialOutage",
    outageFirstEscalation: "Outage_ESC1",
    outageSecondEscalation: "Outage_ESC2",
    partialOutageFirstEscalation: "PartialOutage_ESC1",
    partialOutageSecondEscalation: "PartialOutage_ESC2",
    falseAlarm: "falseAlarm"
};