import './styles.scss';

const HelpCarousel = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-interval="false"
    >
      <ol className="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      </ol>
      <div className="carousel-inner carousel-content">
        <div className="carousel-item active custom-scroll-intro">
          <h1>Dashboard</h1>
          <span className="carousal-help-text">
            This dashboard page provides an overview of ongoing incidents and maintenance activities.
            It includes a summary of the number of incidents and maintenance activities, configuration section,
            detailed listings of open incidents, and options for filtering and navigating the data.
            <br></br>
            <br></br>
            <ol>
              <li>
                <b>Configuration Button</b>
                The configuration section
                allows users to customize frequency of emails notifications for incidents and maintenance updates. It consists of three sections:
                <ul>
                  <li>
                    <b>Escalation Email Frequency : </b> Configure the intervals at which the first and second escalation notifications will be sent to the internal team regarding ongoing incidents.
                    The first internal escalation will be sent after the set time from the incident occurrence. 
                    The second internal escalation email will follow after the set time from the first escalation.
                  </li>
                  <li>
                    <b>Update Email Frequency : </b> Configure the intervals at which the first and second updates will be sent to customers regarding ongoing incidents.
                    The first update email will be sent after the set time from the incident occurrence. 
                    The second update email will follow after the set time from the first update.
                  </li>
                  <li>
                    <b>Notification Settings : </b> Choose whether you want to enable / disable  notifications for hidden services, different types of incidents (outages, partial outages, or maintenance) and transition of incidents.
                  </li>
                </ul>
              </li>
              <br></br>
              <li>
                <b>Summary Cards</b>
                <ul>
                  <li>
                    <b>Ongoing Outage : </b> Displays the total number of ongoing outages. An outage refers to a complete loss of service.
                  </li>
                  <li>
                    <b>Ongoing Partial Outage / Degradation : </b> Shows the number of incidents where services are partially down or
                    experiencing performance degradation.
                  </li>
                  <li>
                    <b>Ongoing or Upcoming Planned Maintenance : </b> Indicates the count of ongoing or scheduled maintenance activities.
                  </li>
                </ul>
              </li>
              <br></br>
              <li>
                <b>Open Incidents and Upcoming Planned Maintenance</b>
                <ul>

                  <li><b>Service Filter Dropdown : </b> Use this dropdown to filter incidents by specific services.
                    This helps focus on incidents relevant to particular region.
                  </li>
                  <li><b>Incident ID Search : </b> Enter an Incident ID to quickly locate specific incidents.
                    This is useful for tracking or investigating particular issues.
                  </li>

                  <li><b>Incident List : </b>Each incident includes an Incident ID, Start Time (UTC), Incident Type, Product, and Service impacted.
                    This information helps identify and categorize the nature and scope of the incident.
                  </li>

                  <li><b>Incident Status Icon : </b>A Yellow icon indicates planned maintenance, an Orange icon signifies a partial outage,
                    and a Red icon represents a full outage where services are currently disrupted.
                  </li>
                </ul>
              </li>
              <br></br>
              If there are no current incidents or upcoming maintenance activities,
              the page will display a message indicating that there are no open incidents or upcoming maintenance activities.
              Also a link will be provided to initiate new incident or maintenance activities.
              <br></br>
              <br></br>
              <br></br>
            </ol>
          </span>
        </div>

        <div className="carousel-item custom-scroll-intro">
          <h1>Incident Management</h1>
          <span className="carousal-help-text">
            This page provides a comprehensive list of all incidents, whether they are open or resolved.
            You can view detailed information about each incident.
            Use the filters and search options to find specific incidents quickly.
            <br></br>
            <br></br>
            <ol>
              <li>
                <b>Create Incident</b><br></br>
                Clicking on Create Incident button navigates to the incident creation page.
                On this page, you can enter the following details:
                <ul>
                  <li>
                    <b>Incident ID : </b>Enter a unique identifier for the new incident.
                  </li>
                  <li>
                    <b>Incident Type : </b>Select a type from categories such as Outage, Planned Maintenance, or Partial Outage.
                  </li>
                  <li>
                    <b>Service : </b>Select the service affected by the incident.
                  </li>
                  <li>
                    <b>Incident State : </b> Select a state to indicate whether it is a new incident or if it's being resolved.
                  </li>
                  <li>
                    <b>Start Time : </b> Enter the date and time when the incident started.
                  </li>
                  <li>
                    <b>End Time : </b> Enter the end time if the incident is being resolved.
                  </li>
                </ul>
              </li>
              <br></br>
              <li>
                <b>All Incidents</b>
                <ul>

                  <li><b>Service Filter Dropdown : </b> Use this dropdown to filter incidents by specific services.
                    This helps focus on incidents relevant to particular region.
                  </li>
                  <li><b>Incident ID Search : </b> Enter an Incident ID to quickly locate specific incidents.
                    This is useful for tracking or investigating particular issues.
                  </li>

                  <li><b>Date Filter : </b> Use the date filter to narrow down incidents based on the time frame.
                  </li>
                  <li><b>Incident List : </b>Each incident includes an Incident ID, Start Time (UTC), Incident Type, Product, and Service impacted.
                    This information helps identify and categorize the nature and scope of the incident.
                  </li>

                  <li><b>Incident Status Icon : </b>A Yellow icon indicates planned maintenance, an Orange icon signifies a partial outage,
                    a Red icon represents a full outage and a Green icon indicates resolved incidents.
                  </li>
                </ul>
              </li>
              <br></br>
              <br></br>
              <br></br>
            </ol>
          </span>
        </div>

        <div className="carousel-item custom-scroll-intro">
          <h1>Service Management</h1>
          <span className="carousal-help-text">
            The Service Management page displays all services in a hierarchical tree structure with parent and child relationships,
            allowing for intuitive organization and management. Services are listed with parent and child nodes,
            and you can easily rearrange them by dragging and dropping nodes within the same parent or between different parent services.
            This feature provides flexibility in managing the service hierarchy.
            <br></br>
            <br></br>
            <ol>
              <li>
                <b>Create Service</b><br></br>
                Clicking on Create Service button to navigate to the service creation page.
                On this page, you can enter the following details:
                <ul>
                  <li>
                    <b>Name : </b>The name of the service.
                  </li>
                  <li>
                    <b>Email Name : </b>The email name which is used to send notifications.
                  </li>
                  <li>
                    <b>Description : </b>A brief description of the service.
                  </li>
                  <li>
                    <b>Product Trigger : </b>Enter the 4me product trigger.
                  </li>
                  <li>
                    <b>Service Triiger : </b>Enter the 4me service trigger.
                  </li>
                  <li>
                    <b>Peer Priority : </b>The position where the new service should be placed within the list of services.
                  </li>
                  <li>
                    <b>Is Hidden : </b>Select the radio button to indicate whether the service should be hidden from the list of services.
                  </li>
                  <li>
                    <b>Main Component : </b>Specify if this service will be a main component. If not, select a main component from the drop-down list.
                  </li>
                  <li>
                    <b>Configure Templates : </b>You can also configure templates that will be used for incidents related to this service.
                    If you don’t configure specific templates, the default main component templates will be used while sending out emails.
                  </li>
                </ul>
              </li>
              <br></br>
              <li>
                <b>Edit Services</b>
                <ul>
                  <li> The Edit button allows you to modify existing service details.
                    On the edit page, you can update all fields except for the product triggers, which are not editable. This includes updating the name,
                    email, description, service trigger, peer priority, isHidden status, and add or remove templates as needed.
                  </li>
                </ul>
              </li>
              <br></br>
              <br></br>
              <br></br>
            </ol>
          </span>
        </div>

        <div className="carousel-item custom-scroll-intro">
          <h1>Contact List Management</h1>
          <span className="carousal-help-text">
            The Contact List Management section lets you add and manage external and escalation contacts.
            In the External Contacts tab, you can subscribe contacts to services and update their details,
            ensuring they receive incident notifications.
            The Escalation Contacts tab allows you to assign escalation levels to contacts, prioritizing responses to critical issues.
            <br></br>
            <br></br>
            <ol>
              <li>
                <b>External Contacts</b><br></br>
                Clicking on Add Contact button navigates to the contact creation page.
                On this page, you can enter the following details:
                <ul>
                  <li>
                    <b>Email Address : </b>Enter the email id that needs to be added in external contacts.
                  </li>
                  <li>
                    <b>Services : </b>Select the services for which the contact needs to be subscribed. By doing so they will
                    receive email notifications for incidents related to the selected services.
                  </li>
                </ul>
                <br></br>
                <b>Export : </b>Clicking this button navigates to a Google Sheet that contains a comprehensive list of all services and their associated subscribers,
                allowing for easy access and management of subscriber information.
              </li>
              <br></br>
              <li>
                <b>Escalation Contacts</b><br></br>
                We have 2 fields in the escalation contacts tab:
                <ul>
                  <li><b>Email address : </b> Enter the email id that needs to be added in escalation contacts.
                  </li>
                  <li><b>Escalation Level : </b> We have two escalation levels.
                    Contacts in Level 1 will receive an email notification if the incident has started and lasts for 2 hour.
                    Contacts in Level 2 will be notified if the incident continues and lasts for 3 hours.
                  </li>
                </ul>
                Once filling the details, click on the Add Contact button to add the contact.<br></br>
                <br></br>
                In the External Contacts and Escalation Contacts tabs, contacts are displayed in a table view.
                Each tab features a search option to find contacts by email. In the External Contacts tab, you can filter contacts by
                the services they are subscribed to, while the Escalation Contacts tab allows filtering by assigned escalation levels.
                The table includes an Actions column with options to edit subscribed services or escalation levels and delete contacts if necessary.
                These features enable efficient management and quick access to specific contacts.
              </li>
              <br></br>
              <br></br>
              <br></br>
            </ol>
          </span>
        </div>

        <div className="carousel-item custom-scroll-intro">
          <h1>Portal User Management</h1>
          <span className="carousal-help-text">
            The User Management section allows administrators to efficiently manage user access and permissions within the portal.
            Here, admins can add new users, assign appropriate roles, and update user information.
            This section ensures that each user has the necessary access to perform their tasks while maintaining security and
            control over the system.
            <br></br>
            <br></br>
            <ol>
              <li>
                <b>Add User</b><br></br>
                <ul>
                  <li>
                    <b>Email address : </b>Enter the email id of the user that needs to be added.
                  </li>
                  <li>
                    <b>Role : </b>Select a role from the dropdown list. Roles define the permissions and access levels for each user.
                  </li>
                </ul>
                Clicking the Add User button will add the user to the portal.
              </li>
              <br></br>
              <li>
                <b>Roles</b>
                <ul>

                  <li><b>Admin : </b> Has comprehensive access to all sections and functionalities within the entire portal.
                  </li>
                  <li><b>Super User : </b> Has restricted access, limited to the dashboard and incident management sections only.
                    Super Users can view and manage incidents and monitor the
                    dashboard but cannot access or interact with other sections of the portal.
                  </li>
                  <li><b>User : </b> Has view-only access across the entire portal but cannot access the User Management tab.
                    Users can view information on the dashboard,
                    incidents, services, and contacts, but are unable to perform any actions or modifications on any page.
                  </li>
                </ul>
              </li>
              <br></br>

              All users are displayed in a detailed table view that includes the user creation date,
              email addresses, and assigned roles. The Actions column allows administrators to edit user details,
              modify roles, or delete users. The email and role filter enables efficient searching and management of users. This layout ensures
              that administrators can easily oversee and maintain user information and access permissions across the portal.

              <br></br>
              <br></br>
              <br></br>
            </ol>
          </span>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        data-target="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        data-target="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div >
  );
};

export default HelpCarousel;
