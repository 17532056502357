export const RADIO_INPUT_CONSTANTS = {
  input: 'Is this a False Alarm?',
  yes: 'Yes',
  no: 'No',
};

export interface IRadioInputProps {
  onFalseAlarmChange?: (value: boolean) => void;
  value?: boolean;
  isDisabled: boolean;
}
