import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "@trimbleinc/modus-react-bootstrap";
import exp from "constants";
import { CREATE_FORM_CONSTANTS } from "features/ServiceManagement/components/AddService/components/CreateServiceForm/CreateServiceForm.constants";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "components/Common/Loading";
import Editor from "components/Common/SummerNote/Editor";
import './styles.scss';
import AddLanguageModal from "components/Common/AddLanguageModal/AddLanguageModal";
import { add, set } from "date-fns";
import { updateServiceDetails } from "services/serviceManagement.service";
import { ToastContainer, toast } from "react-toastify";
import { MANAGE_SERVICE_CONSTANTS } from "./ManageService.constants";
import { domoColumns, transformMessageType } from "constants/MessageType";
import RouterPath from "config/routerPath";
import logEvent from "utils/TrimbleInsightsLogger.utils";

const ManageService = ({ serviceData }: any) => {
    const navigate = useNavigate();
    const { register, handleSubmit, reset, setValue, formState, formState: { errors } } = useForm({
        defaultValues: {
            [CREATE_FORM_CONSTANTS.LANGUAGE.KEY]: serviceData.language,
        }
    });

    const [existingData, setExistingData] = useState(serviceData);
    const [isFormValid, setIsFormValid] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isHidden, setIsHidden] = useState(serviceData.isHidden);
    const [selectedDeleteLanguages, setSelectedDeleteLanguages] = useState([]);

    const handleRadioChange = (e: { target: { value: string; }; }) => {
        const value = e.target.value === "true";
        setIsHidden(value);
        setValue(CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY, value.toString());
    };

    const initiateCancel = () => {
        Swal.fire({
            title: `<h4>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
            icon: 'error',
            html: `<p>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_CLOSE}<br/>`,
            showCancelButton: true,
            confirmButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_CANCEL_TEXT,
            cancelButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_DISCARD_TEXT,
            preConfirm: async () => {
                try {
                    Swal.close();
                    navigate(RouterPath.PRIVATE_ROUTE.serviceManagement);
                } catch (error) {
                    toast.error('Something went wrong');
                }
            },
        });
    };


    const onSubmit = async (data: any) => {
        try {
            const updatedData = prepareUpdatedData(data);
            const updateDetails = findDifferences(existingData, updatedData);
            if (selectedDeleteLanguages.length > 0)
                updateDetails[CREATE_FORM_CONSTANTS.DELETE_LANGUAGE.KEY] = selectedDeleteLanguages;
            const modifyResponse = await toast.promise(
                updateServiceDetails(updateDetails),
                {
                    pending: MANAGE_SERVICE_CONSTANTS.UPDATE_SERVICE_IN_PROGRESS,
                }
            );
            if (modifyResponse.status == 200) {
                toast.success(modifyResponse?.data ?? MANAGE_SERVICE_CONSTANTS.UPDATE_SERVICE_SUCCESS,
                    {
                        autoClose: 1000,
                        onClose: () => navigate(RouterPath.PRIVATE_ROUTE.serviceManagement)
                    })
                //log events to DOMO
                logEvent('MANAGE_SERVICE', 'USER_ACTIVITY', {
                    service: updateDetails.service,
                    product: updateDetails.product,
                    ...(updateDetails?.updatedName && { updatedName: updateDetails.updatedName }),
                    ...(updateDetails?.updatedEmailName && { updatedDisplayName: updateDetails.updatedEmailName }),
                    ...(updateDetails?.updatedService && { updatedService: updateDetails.updatedService }),
                    ...(updateDetails?.updatedPeerPriority && { updatedPeerPriority: updateDetails.updatedPeerPriority }),
                    ...(updateDetails?.updatedLanguage && { templateLanguage: updateDetails.updatedLanguage }),
                    ...(updateDetails?.updatedIsHidden && { updatedIsHidden : updateDetails.updatedIsHidden }),
                    ...(updateDetails?.isDeletedLanguage && { 
                        deletedLanguages: `"${updateDetails.isDeletedLanguage.join(',')}"`
                    }),
                    actions: 'Update',
                    resource: 'Services'
                });

                if (updateDetails.updatedTemplate && updateDetails.updatedTemplate.length > 0) {
                    updateDetails.updatedTemplate.forEach((template: { language: any; typeMessages: any; }) => {
                        const domoData = {
                            service: updateDetails.service,
                            product: updateDetails.product,
                            actions: 'Update',
                            templateLanguage: template.language
                        };
        
                        const typeMessages = template.typeMessages;
        
                        typeMessages.forEach((message: { type: string; message: string; }) => {
                            for (let [key, value] of Object.entries(domoColumns)) {
                                if (message.type === value) {
                                    (domoData as any)[key] = `"${message.message.replace(/"/g, '""')}"`;
                                }
                            }
                        });
        
                        // Log the update template event
                        logEvent('UPDATE_TEMPLATE', 'USER_ACTIVITY', domoData);
                    });
                }
        
        }
            if (modifyResponse.status == 400 || modifyResponse.status == 500) {
            toast.error(modifyResponse?.data ?? MANAGE_SERVICE_CONSTANTS.UPDATE_SERVICE_ERROR)
            logEvent('MANAGE_SERVICE', 'EXCEPTION', {
                service: updateDetails.service,
                product: updateDetails.product,
                ...(updateDetails?.updatedName && { updatedName: updateDetails.updatedName }),
                ...(updateDetails?.updatedEmailName && { updatedDisplayName: updateDetails.updatedEmailName }),
                ...(updateDetails?.updatedService && { updatedService: updateDetails.updatedService }),
                ...(updateDetails?.updatedPeerPriority && { updatedPeerPriority: updateDetails.updatedPeerPriority }),
                ...(updateDetails?.updatedLanguage && { templateLanguage: updateDetails.updatedLanguage }),
                actions: 'Update',
                resource: 'Services',
                error: modifyResponse?.data ? `"${modifyResponse?.data}"` : MANAGE_SERVICE_CONSTANTS.UPDATE_SERVICE_ERROR
            });
        }
        if (modifyResponse.status == 401) {
            toast.error("Unauthorized")
        }
    }
        catch (error) {
        toast.error('Something went wrong');
    }
}

useEffect(() => {
    reset(serviceData);
    if (serviceData) {
        setIsLoading(true);
        const initialLanguage = serviceData.language;
        setSelectedLanguage(initialLanguage);
        updateEditorValues(initialLanguage);
    }
}, [serviceData]);

useEffect(() => {
    if (Object.keys(formState.errors)?.length == 0) {
        setIsFormValid(true)
    } else {
        setIsFormValid(false)
    }
}, [formState])

const [selectedLanguage, setSelectedLanguage] = useState(serviceData.language);
const defaultMessageTypes = [
    'New_Outage', 'Updating_Outage_1', 'Updating_Outage_2', 'Resolved_Outage',
    'New_PlannedMaintenance', 'Updating_PlannedMaintenance_1', 'Resolved_PlannedMaintenance',
    'New_PartialOutage', 'Updating_PartialOutage_1', 'Updating_PartialOutage_2', 'Resolved_PartialOutage',
    'Transition_Outage', 'Transition_PartialOutage', "Outage_ESC1", "Outage_ESC2", "PartialOutage_ESC1", "PartialOutage_ESC2", "falseAlarm"
];
const initialEditorValues: { [key: string]: string } = defaultMessageTypes.reduce((acc, type) => {
    acc[type] = '';
    return acc;
}, {} as { [key: string]: string });

const [editorValues, setEditorValues] = useState(initialEditorValues);
const languages = serviceData.template.map((template: { language: any; }) => template.language);

const updateEditorValues = (language: any) => {
    if (language) {
        const lang_template = serviceData.template.find((t: { language: any; }) => t.language === language);
        if (lang_template) {
            const newEditorValues: { [key: string]: string } = {};
            defaultMessageTypes.forEach((type) => {
                const message = getMessageByType(lang_template, type);
                newEditorValues[type] = message !== '' ? message : '';
            });
            setEditorValues(newEditorValues);
        } else {
            setEditorValues(initialEditorValues);
        }
    }
};

const handleLanguageChange = (event: { target: { value: any; }; }) => {
    const selectedLang = event.target.value;
    setSelectedLanguage(selectedLang);
    setValue(CREATE_FORM_CONSTANTS.LANGUAGE.KEY, selectedLang);
    updateEditorValues(selectedLang);
};

const getMessageByType = (template: { typeMessages: any[]; }, type: string) => {
    const messageObj = template.typeMessages.find((m: { type: string; }) => m.type === type);
    return messageObj ? messageObj.message : '';
};

const [template, setTemplate] = useState(serviceData.template);
const handleEditorChange = (field: string, value: string) => {
    const updatedTemplate = serviceData.template.map((template: any) => {
        if (template.language === selectedLanguage) {
            const updatedTypeMessages = template.typeMessages.map((typeMessage: any) => {
                if (typeMessage.type === field) {
                    return {
                        ...typeMessage,
                        message: value // Update existing message
                    };
                }
                return typeMessage;
            });
            const existingType = template.typeMessages.find((typeMessage: any) => typeMessage.type === field);
            if (!existingType) {
                // Add new message type if it doesn't exist
                updatedTypeMessages.push({
                    type: field,
                    message: value
                });
            }
            return {
                ...template,
                typeMessages: updatedTypeMessages
            };
        }
        return template;
    });

    setTemplate([...updatedTemplate]);

    setEditorValues(prevValues => ({
        ...prevValues,
        [field]: value
    }));
};

// PREVIEW MODAL
const [previewShow, setPreviewShow] = useState(false);
const handlePreviewClose = () => {
    setPreviewShow(false);
}
const handlePreviewShow = () => setPreviewShow(true);

// EDIT MODAL
const [editShow, setEditShow] = useState(false);
const handleEditClose = () => {
    updateEditorValues(selectedLanguage)
    setEditShow(false);
}
const handleEditShow = () => setEditShow(true);
const handleEditSave = () => setEditShow(false);

// LANGUAGE MODAL
const modalRef = useRef<any>(null);
const [source, setSource] = useState(''); // ['template', 'edit-template'
const [newLangShow, setNewLangShow] = useState(false);
const handleNewLangClose = () => {
    setNewLangShow(false);
    if (source === 'template') {
        setAddTemplate([]);
        if (modalRef.current) {
            modalRef.current.clearEditorValues();
        }
    }
}
const handleNewLangShow = (source: string) => {
    setSource(source);
    setNewLangShow(true);
}
const handleNewLangDelete = () => {
    setAddTemplate([]);
    if (modalRef.current) {
        modalRef.current.clearEditorValues();
    }
}

const [addTemplate, setAddTemplate] = useState<{ language: string; typeMessages: any; }[]>([]);

const handleSaveTemplate = (rawMessage: any, language: string) => {
    const typeMessages = cleanseMessages(rawMessage);
    const newTemplate = {
        language,
        typeMessages,
    };
    setAddTemplate([newTemplate]);
    setNewLangShow(false);
};

const cleanseMessages = (rawMessage: any) => {
    const typeMessages = rawMessage.map((message: { message: string; }) => ({
        ...message,
        message: message.message.replace(/<p>\s*<br>\s*<\/p>/g, '')
    }));
    return typeMessages;
};

const removeEmptyValues = (obj: any) => {
    const cleansedObj: any = {};
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== '') {
            cleansedObj[key] = obj[key];
        }
    }
    return cleansedObj;
};

const findDifferences = (obj1: any, obj2: any) => {
    const differences: Record<string, any> = {};
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    const keyMappings: Record<string, string> = {
        'statuscastId': 'statuscastServiceID',
        'statuscastServiceParentId': 'parentStatuscastServiceID'
    };

    // Add default keys from obj1
    const defaultKeys = ['product', 'service', 'statuscastId', 'statuscastServiceParentId', 'peerPriority'];
    defaultKeys.forEach((key) => {
        if (obj1.hasOwnProperty(key)) {
            const mappedKey = keyMappings[key] || key;
            differences[mappedKey] = obj1[key];
        }
    });

    const isEqual = (val1: any, val2: any) => {
        if (Array.isArray(val1) && Array.isArray(val2)) {
            return JSON.stringify(val1) === JSON.stringify(val2);
        }
        return val1 === val2;
    };

    allKeys.forEach((key) => {
        if (!isEqual(obj1[key], obj2[key])) {
            differences[`updated${key.charAt(0).toUpperCase() + key.slice(1)}`] = obj2[key];
        }
    });

    if (differences['updatedPeerPriority']) {
        differences['updatedParentStatuscastServiceID'] = differences['parentStatuscastServiceID'];
    }
    return differences;
};

const prepareUpdatedData = (data: any) => {
    let updatedData: any = removeEmptyValues(data);
    if (template.length > 0) {
        const languageIndex = template.findIndex((item: { language: any; }) => item.language === selectedLanguage);
        const updatedTemplate = [...template];
        const cleansedMessages = cleanseMessages(template[languageIndex].typeMessages);
        updatedTemplate[languageIndex].typeMessages = cleansedMessages;
        updatedData.template = updatedTemplate;
    }
    if (addTemplate && addTemplate.length > 0) {
        updatedData.template.push(...addTemplate);
        if (
          serviceData.language === undefined ||
          serviceData.language === null
        ) {
          updatedData = {
            ...updatedData,
            language: addTemplate[0].language,
          };
        }
    }
    updatedData = {
        ...updatedData,
        isHidden: updatedData?.isHidden === "true",
        peerPriority: parseInt(updatedData?.peerPriority)
    };
    return updatedData;
};



const handleCheckboxChange = (language: string) => {
    if (selectedDeleteLanguages.includes(language as never)) {
        setSelectedDeleteLanguages(selectedDeleteLanguages.filter((item: string) => item !== language));
    } else {
        setSelectedDeleteLanguages([...selectedDeleteLanguages, language as never]);
    }
};

const [newLangPreview, setNewLangPreview] = useState(false);
const handleNewLangPreview = () => {
    setNewLangPreview(true);
}
const handleNewLangPreviewClose = () => setNewLangPreview(false);

return (
    <>
        <h1 className="heading">Edit Service</h1>
        {!isLoading && <Loading />}
        {isLoading && (
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="create-service-card">
                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.NAME.LABEL}</h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicName">
                                <Form.Control
                                    as="input"
                                    className={`input-border`}
                                    placeholder={CREATE_FORM_CONSTANTS.NAME.PLACEHOLDER}
                                    defaultValue={serviceData.name}
                                    {...register(CREATE_FORM_CONSTANTS.NAME.KEY, {
                                        required: { value: true, message: CREATE_FORM_CONSTANTS.NAME.ERROR_TEXT }
                                    })}
                                />
                                {errors?.[CREATE_FORM_CONSTANTS.NAME.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[CREATE_FORM_CONSTANTS.NAME.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.EMAIL_NAME.LABEL}</h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicRegion">
                                <Form.Control
                                    as="input"
                                    className={`input-border`}
                                    placeholder={CREATE_FORM_CONSTANTS.EMAIL_NAME.PLACEHOLDER}
                                    defaultValue={serviceData.emailName}
                                    {...register(CREATE_FORM_CONSTANTS.EMAIL_NAME.KEY, {
                                        required: { value: true, message: CREATE_FORM_CONSTANTS.EMAIL_NAME.ERROR_TEXT }
                                    })}
                                />
                                {errors?.[CREATE_FORM_CONSTANTS.EMAIL_NAME.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[CREATE_FORM_CONSTANTS.EMAIL_NAME.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.DESCRIPTION.LABEL}</h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicDescription">
                                <Form.Control
                                    as="input"
                                    className={`input-border`}
                                    placeholder={CREATE_FORM_CONSTANTS.DESCRIPTION.PLACEHOLDER}
                                    defaultValue={serviceData.description}
                                    {...register(CREATE_FORM_CONSTANTS.DESCRIPTION.KEY, {
                                    })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.PRODUCT_NAME.LABEL}</h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicCode">
                                <Form.Control
                                    as="input"
                                    className={`input-border`}
                                    defaultValue={serviceData.product}
                                    readOnly
                                    {...register(CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY, {
                                        required: { value: true, message: CREATE_FORM_CONSTANTS.PRODUCT_NAME.ERROR_TEXT }
                                    })}
                                />
                                {errors?.[CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.SERVICE_NAME.LABEL}</h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicAdditionalCode">
                                <Form.Control
                                    as="input"
                                    className={`input-border`}
                                    defaultValue={serviceData.service}
                                    {...register(CREATE_FORM_CONSTANTS.SERVICE_NAME.KEY, {
                                        required: { value: true, message: CREATE_FORM_CONSTANTS.SERVICE_NAME.ERROR_TEXT }
                                    })}
                                />
                                {errors?.[CREATE_FORM_CONSTANTS.SERVICE_NAME.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[CREATE_FORM_CONSTANTS.SERVICE_NAME.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.PEER_PRIORITY.LABEL}
                                <OverlayTrigger
                                    key="overlay2"
                                    placement="right"
                                    overlay={<Tooltip id="icontooltip">{CREATE_FORM_CONSTANTS.PEER_PRIORITY.TOOLTIP}</Tooltip>}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mi-outline mi-info-outlined" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                                        <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                                    </svg>
                                </OverlayTrigger></h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicAdditionalCode">
                                <Form.Control
                                    type="number"
                                    min="1"
                                    className={`input-border`}
                                    defaultValue={serviceData.peerPriority}
                                    {...register(CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY, {
                                        required: { value: true, message: CREATE_FORM_CONSTANTS.PEER_PRIORITY.ERROR_TEXT }
                                    })}
                                />
                                {errors?.[CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col md={6}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.IS_HIDDEN.LABEL}
                                <OverlayTrigger
                                    key="overlay2"
                                    placement="right"
                                    overlay={<Tooltip id="icontooltip">{CREATE_FORM_CONSTANTS.IS_HIDDEN.TOOLTIP}</Tooltip>}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mi-outline mi-info-outlined" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                                        <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                                    </svg>
                                </OverlayTrigger>
                            </h4></Form.Label>
                        </Col>
                        <Col md={6}>
                            <fieldset>
                                <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                                    <Form.Check
                                        type="radio"
                                        label="True"
                                        id="hidden-true"
                                        value="true"
                                        custom
                                        className="mr-3"
                                        checked={isHidden === true}
                                        {...register(CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY, {
                                        })}
                                        onChange={handleRadioChange}

                                    />
                                    <Form.Check
                                        type="radio"
                                        label="False"
                                        id="hidden-false"
                                        value="false"
                                        custom
                                        checked={isHidden === false}
                                        {...register(CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY, {
                                        })}
                                        onChange={handleRadioChange}
                                    />
                                </Form.Group>
                            </fieldset>
                        </Col>
                    </Row>

                    {serviceData.language && (
                        <Row className="form-row">
                            <Col md={6}>
                                <Form.Label><h4>{CREATE_FORM_CONSTANTS.LANGUAGE.LABEL}</h4></Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formBasicAdditionalCode">
                                    <Form.Control
                                        as="select"
                                        className={`input-border`}
                                        custom
                                        defaultValue={serviceData.language}
                                        {...register(CREATE_FORM_CONSTANTS.LANGUAGE.KEY, {
                                            required: { value: true, message: CREATE_FORM_CONSTANTS.LANGUAGE.ERROR_TEXT }
                                        })}
                                        onChange={handleLanguageChange}
                                    >
                                        {languages.map((lang: string, index: number) => (
                                            <option key={index} value={lang}>
                                                {lang}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {errors?.[CREATE_FORM_CONSTANTS.LANGUAGE.KEY]?.message && (
                                        <Form.Control.Feedback type="invalid" className="field-error-message">
                                            {errors[CREATE_FORM_CONSTANTS.LANGUAGE.KEY]?.message?.toString()}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                {serviceData.language && (
                                    <>
                                        <Button variant="outline-primary" className="btn-icon-only mr-2" onClick={handlePreviewShow}>
                                            <i className="modus-icons" aria-hidden="true">preview</i>
                                        </Button>
                                        <Button variant="outline-primary" className="btn-icon-only mr-2" onClick={handleEditShow} style={{ marginLeft: '10px', marginRight: '10px' }}>
                                            <i className="modus-icons" aria-hidden="true">file_edit</i>
                                        </Button></>
                                )}
                            </Col>
                        </Row>
                    )}

                    {!addTemplate || addTemplate.length === 0 && (
                        <Row>
                            <Col md={6} />
                            <Col md={6} className="configure-button">
                                <Button style={{ marginTop: '9px' }} variant="tertiary" size="sm" onClick={() => handleNewLangShow('template')}>{CREATE_FORM_CONSTANTS.LANGUAGE.ADD_LANGUAGE_BUTTON}</Button>
                                <Form.Text id="currencyHelpBlock" className="drop-down-note" muted>
                                    [NOTE: By default the templates from parent service will be used]
                                </Form.Text>
                            </Col>
                        </Row>
                    )}


                        <Modal size="xl" show={previewShow} centered onHide={handlePreviewClose}>
                            <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
                                <Modal.Title>Messages</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="manage-service-modal">
                                {Object.entries(editorValues)
                                    .filter(([type, message]) => message !== '')
                                    .map(([type, message]) => {
                                        const cleanedMessage = message.replace(/<p>\s*<br>\s*<\/p>/g, '');
                                        return (
                                            <Row className='form-row' key={type}>
                                                <Col md={12}>
                                                    <p><strong>{transformMessageType(type)}:</strong> {cleanedMessage}</p>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                            </Modal.Body>
                        </Modal>

                        <Modal size="xl" show={editShow} centered onHide={handleEditClose}>
                            <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
                                <Modal.Title>Messages</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="manage-service-modal">
                                {defaultMessageTypes.map(type => (
                                    <Row className='form-row' key={type}>
                                        <Col md={4}>
                                            <Form.Label><h4>{transformMessageType(type)}</h4></Form.Label>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Group controlId={`formBasic${type}`}>
                                                <Editor
                                                    id={`${type}Editor`}
                                                    value={editorValues[type]}
                                                    onChange={(value: any) => handleEditorChange(type, value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleEditSave}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    <AddLanguageModal
                        ref={modalRef}
                        langShow={newLangShow}
                        handleLangClose={handleNewLangClose}
                        defaultMessageTypes={defaultMessageTypes}
                        onSaveTemplate={handleSaveTemplate}
                    />
                    {addTemplate && addTemplate.length > 0 && (
                        <Row className="form-row" style={{ marginTop: '20px' }}>
                            <Col md={6}>
                                <Form.Label><h4>New Language</h4></Form.Label>
                            </Col>
                            <Col md={6}>
                                <Button variant="text-primary" className="btn-icon-only mr-2" onClick={handleNewLangPreview}>
                                    <i className="modus-icons" aria-hidden="true">preview</i>
                                </Button>
                                <Button variant="text-primary" className="btn-icon-only mr-1" onClick={() => handleNewLangShow('edit-template')}>
                                    <i className="modus-icons" aria-hidden="true">file_edit</i>
                                </Button>
                                <Button variant="text-danger" className="mr-2" onClick={handleNewLangDelete}>
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    )}

                        <Modal size="xl" show={newLangPreview} centered onHide={handleNewLangPreviewClose}>
                            <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
                                <Modal.Title>New language preview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="manage-service-modal">
                                {addTemplate.map((temp: any) => (
                                    <div key={temp.language} style={{ marginBottom: '20px' }}>
                                        <h5>Language: {temp.language}</h5>
                                        {temp.typeMessages
                                            .filter(({ message }: any) => message !== '')
                                            .map(({ type, message }: any) => {
                                                const cleanedMessage = message.replace(/<p>\s*<br>\s*<\/p>/g, '');
                                                return (
                                                    <Row className='form-row' key={type}>
                                                        <Col md={12}>
                                                            <p><strong>{transformMessageType(type)}:</strong> {cleanedMessage}</p>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                    </div>
                                ))}
                            </Modal.Body>
                        </Modal>

                    {languages && languages.length > 0 && (
                        <Row className="form-row">
                            <Col md={6}>
                                <Form.Label><h4>
                                    {CREATE_FORM_CONSTANTS.DELETE_LANGUAGE.LABEL}
                                </h4></Form.Label>
                            </Col>
                            <Col md={6}>
                                {languages.map((language: any, index: any) => (
                                    <Form.Check
                                        key={index}
                                        custom
                                        inline
                                        type="checkbox"
                                        id={`custom-inline-checkbox-${index}`}
                                        label={language}
                                        className="mr-3"
                                        checked={selectedDeleteLanguages.includes(language as never)}
                                        onChange={() => handleCheckboxChange(language)}
                                    />
                                ))}
                            </Col>
                        </Row>
                    )}
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: '40px' }}
                >
                    <Button
                        variant="secondary"
                        style={{ marginRight: '10px' }}
                        onClick={initiateCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={!isFormValid}
                    >
                        <i
                            className="modus-icons material-icons left-icon"
                            aria-hidden="true"
                        >
                            save_disk
                        </i>
                        Update
                    </Button>
                </div>
            </Form>
        )}
        <ToastContainer />
    </>
);
}
export default ManageService;