import { Button, Card, OverlayTrigger, Tooltip } from '@trimbleinc/modus-react-bootstrap';
import React, { useState } from 'react';
import EscalationConfiguration from './components/EscalationConfiguration/EscalationConfiguration';
import UpdateConfiguration from './components/UpdateConfiguration/UpdateConfiguration';
import NotificationConfiguration from './components/NotificationConfiguration/NotificationConfiguration';
import { CONFIGURATIONS_CONSTANTS } from './Configurations.constants';

function Configurations() {
    const [isEscalationTimerExpand, setIsEscalationTimerExpand] = useState(false);
    const [isUpdateTimerExpand, setIsUpdateTimerExpand] = useState(false);
    const [isNotificationsExpand, setIsNotificationsExpand] = useState(false);

    const handleEscalationTimerExpand = () => {
        setIsEscalationTimerExpand(!isEscalationTimerExpand);
    };

    const handleUpdateTimerExpand = () => {
        setIsUpdateTimerExpand(!isUpdateTimerExpand);
    };

    const handleNotificationsExpand = () => {
        setIsNotificationsExpand(!isNotificationsExpand);
    };

    return (
        <>
            <div className='configuration'>
                <div className="d-grid gap-1">
                    <Button
                        variant="text-primary"
                        size="lg"
                        className='config-button'
                        onClick={handleEscalationTimerExpand}
                    >
                        <span className="form-label">ESCALATION EMAIL FREQUENCY
                            <OverlayTrigger
                                key="overlay2"
                                placement="right"
                                overlay={<Tooltip id="icontooltip">{CONFIGURATIONS_CONSTANTS.ESCALATION_INFO}</Tooltip>}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mi-outline mi-info-outlined mi-config" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                                    <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                                </svg>
                            </OverlayTrigger></span>
                        <i className="modus-icon material-icons arrow-icon">
                            {isEscalationTimerExpand ? 'expand_less_bold' : 'expand_more_bold'}
                        </i>
                    </Button>
                    <div className={`card-wrapper ${isEscalationTimerExpand ? 'expanded' : 'collapsed'}`}>
                        <Card className="shadow card-color">
                            <EscalationConfiguration />
                        </Card>
                    </div>

                    <Button
                        variant="text-primary"
                        size="lg"
                        block
                        className='config-button'
                        onClick={handleUpdateTimerExpand}
                    >
                        <span className="form-label">UPDATE EMAIL FREQUENCY
                            <OverlayTrigger
                                key="overlay2"
                                placement="right"
                                overlay={<Tooltip id="icontooltip">{CONFIGURATIONS_CONSTANTS.UPDATE_INFO}</Tooltip>}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mi-outline mi-info-outlined mi-config" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                                    <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                                </svg>
                            </OverlayTrigger></span>
                        <i className="modus-icon material-icons arrow-icon">
                            {isUpdateTimerExpand ? 'expand_less_bold' : 'expand_more_bold'}
                        </i>
                    </Button>
                    <div className={`card-wrapper ${isUpdateTimerExpand ? 'expanded' : 'collapsed'}`}>
                        <Card className="shadow card-color">
                            <UpdateConfiguration />
                        </Card>
                    </div>

                    <Button
                        variant="text-primary"
                        size="lg"
                        block
                        className='config-button'
                        onClick={handleNotificationsExpand}
                    >
                        <span className="form-label">NOTIFICATION SETTNGS
                            <OverlayTrigger
                                key="overlay2"
                                placement="right"
                                overlay={<Tooltip id="icontooltip" className='tooltip-config'>{CONFIGURATIONS_CONSTANTS.NOTIFICATION_INFO}</Tooltip>}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mi-outline mi-info-outlined mi-config" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                                    <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                                </svg>
                            </OverlayTrigger></span>
                        <i className="modus-icon material-icons arrow-icon">
                            {isNotificationsExpand ? 'expand_less_bold' : 'expand_more_bold'}
                        </i>
                    </Button>
                    <div className={`card-wrapper ${isNotificationsExpand ? 'expanded' : 'collapsed'}`}>
                        <Card className="shadow card-color">
                            <NotificationConfiguration />
                        </Card>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Configurations;
