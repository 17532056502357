export const NOTIFICATION_CONFIG_CONSTANTS = {
    IS_HIDDEN: {
        LABEL: "Hidden Service",
        KEY: "sendIsHiddenNotification",
        ERROR_TEXT: "Please select a value"
    },
    OUTAGE: {
        LABEL: "Outage",
        KEY: "outage",
        ERROR_TEXT: "Please select a value"
    },
    PARTIAL_OUTAGE: {
        LABEL: "Partial Outage / Degradation",
        KEY: "partial_outage",
        ERROR_TEXT: "Please select a value"
    },
    PLANNED_MAINTENANCE: {
        LABEL: "Planned Maintenance",
        KEY: "planned_maintenance",
        ERROR_TEXT: "Please select a value"
    },
    IS_TRANSITION: {
        LABEL: "Transition of incident",
        KEY: "allowTransitionNotification",
        ERROR_TEXT: "Please select a value"
    },
    SUCCESS_MESSAGE: "Notification configuration updated successfully",
    FAILURE_MESSAGE: "Failed to update notification configuration",
}