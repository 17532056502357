import { Button, Modal } from '@trimbleinc/modus-react-bootstrap';
import DropDownInput from '../../../../components/Common/DropDownInput';
import EndDateTimeInput from '../../../../components/Common/EndDateTimeInput';
import IncidentIdInput from '../../../../components/Common/IncidentIdInput';
import StartDateTimeInput from '../../../../components/Common/StartDateTimeInput';
import {
  details,
  IManageIncidentsProps,
  MANAGE_FORM_CONSTANTS,
  UPDATE_INCIDENT_MACRO_VARIBALES,
  updateRequest,
} from './ManageIncidents.constants';
import MessageBox from '../../../../components/Common/MessageBox';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import RadioInput from '../../../../components/Common/RadioInput';
import { updateIncident } from '../../../../services/incidentManagement.service';
import { fetchServiceMessage } from 'services/serviceManagement.service';
import { isUser } from 'utils/RoleCheck.utils';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import MessageTimeline from '../MessageTimeline';

function ManageIncidents({
  incidentDetails,
  serviceDetails,
  parentURL,
}: IManageIncidentsProps) {
  const [incidentId, setIncidentId] = useState(incidentDetails.incidentId);
  const [selectedProduct, setSelectedProduct] = useState(
    incidentDetails.product
  );
  const [selectedService, setSelectedService] = useState(
    incidentDetails.service
  );
  const [incidentType, setIncidentType] = useState(incidentDetails.type);
  const [isIncidentTypeError, setIsIncidentTypeError] = useState(false);
  const [incidentState, setIncidentState] = useState('');
  const [isIncidentStateError, setIsIncidentStateError] = useState(false);
  const [startDateTime, setStartDateTime] = useState<string | null>(
    incidentDetails.startTimeInUTC
  );
  const [isStartDateTimeError, setIsStartDateTimeError] = useState(false);
  const [endDateTime, setEndDateTime] = useState<string | null>(
    incidentDetails.endTimeInUTC ?? null
  );
  const [isEndDateTimeError, setIsEndDateTimeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFalseAlarm, setIsFalseAlarm] = useState(false);
  const [isEndTimeLessError, setIsEndTimeLessError] = useState(false);
  const [originalMessage, setOriginalMessage] = useState('');
  const [emailName, setEmailName] = useState('');
  const [duration, setDuration] = useState('');
  const [plannedDate, setPlannedDate] = useState('');
  const [plannedTime, setPlannedTime] = useState('');
  const [originalType, setOriginalType] = useState(incidentDetails.type);
  const [isMessageError, setIsMessageError] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);
  const navigate = useNavigate();
  const [showMessageTimeline, setShowMessageTimeline] = useState(false);

  const handleTimelineClose = () => setShowMessageTimeline(false);
  const handleTimelineShow = () => setShowMessageTimeline(true);

  const handleDropDownSelect = (type: string, value: string) => {
    if (type === 'INCIDENT_TYPE_UPDATE') {
      if (value === 'Outage') {
        setIncidentType('Outage');
      } else if (value === 'Partial Outage') {
        setIncidentType('PartialOutage');
      } else if (value === 'Planned Maintenance') {
        setIncidentType('PlannedMaintenance');
      }


      if (value !== '') {
        setIsIncidentTypeError(false);
      }
    } else if (type === 'INCIDENT_STATE_UPDATE') {
      setIncidentState(value);

      if (value !== '') {
        setIsIncidentStateError(false);
      }
    }
  };

  const handleStartDateTimeChange = (value: string | null) => {
    setStartDateTime(value);
    if (value !== null) {
      setIsStartDateTimeError(false);
    }
  };

  const handleEndDateTimeChange = (value: string | null) => {
    setEndDateTime(value);
    if (
      (incidentState === 'Resolved' || incidentState === '') &&
      value !== null &&
      isFalseAlarm
    ) {
      setIsEndDateTimeError(false);
    }

    if (value != null && startDateTime !== null && value > startDateTime) {
      setIsEndTimeLessError(false);
    }
  };

  const frameMessage = (
    message: string,
    emailName?: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    duration?: string,
    plannedDate?: string,
    plannedTime?: string
  ) => {
    message =
      message &&
      message
        .replace(
          /\$\{service\}/g,
          emailName && emailName !== '' ? emailName : '${service}'
        )
        .replace(/\$\{startTime\}/g, startDateTime ?? '${startTime}')
        .replace(/\$\{endTime\}/g, endDateTime ?? '${endTime}')
        .replace(
          /\$\{duration\}/g,
          duration && duration !== '' ? duration : '${duration}'
        )
        .replace(
          /\$\{plannedDate\}/g,
          plannedDate && plannedDate !== '' ? plannedDate : '${plannedDate}'
        )
        .replace(
          /\$\{plannedTime\}/g,
          plannedTime && plannedTime !== '' ? plannedTime : '${plannedTime}'
        )
        .replace(
          /\$\{existingStartTime\}/g,
          startDateTime ?? '${existingStartTime}'
        )
        .replace(/<p>\s*<br>\s*<\/p>/g, '');

    return message;
  };

  const isAbsoluteUrl = (url: string): boolean => {

    return url.startsWith('http://') || url.startsWith('https://');
  };

  const extractUrlsAndValidate = (html: string) => {
    const urlPattern = /href="([^"]+)"/g;

    let match;
    let isValidLink = true;
    while ((match = urlPattern.exec(html)) !== null) {
      const url = match[1];
      const isValid = isAbsoluteUrl(url);
      if (!isValid) {
        isValidLink = false;
      }
    }
    return isValidLink;
  };

  const handleMessageChange = (value: string) => {
    setOriginalMessage(() => value.trim());

    const frameMessageValue = frameMessage(
      value,
      emailName,
      startDateTime,
      endDateTime,
      duration,
      plannedDate,
      plannedTime
    );

    if (frameMessageValue !== '' || frameMessage.length > 0) {
      setIsMessageError(false);
    }

    if (extractUrlsAndValidate(frameMessageValue)) {
      setIsValidLink(true);
    }
  };

  const handleIsFalseAlarmChange = (value: boolean) => {
    setIsFalseAlarm(value);
  };

  const backToHome = () => {
    navigate('/' + parentURL);
  };

  const handleUpdate = async () => {

    let hasError = false;

    if (startDateTime === null) {
      setIsStartDateTimeError(true);
      hasError = true;
    }

    if (incidentState === 'Resolved' && endDateTime === null && !isFalseAlarm) {
      setIsEndDateTimeError(true);
      hasError = true;
    }

    if (
      endDateTime != null &&
      startDateTime !== null &&
      endDateTime <= startDateTime
    ) {
      setIsEndTimeLessError(true);
      hasError = true;
    }

    if (incidentState === 'New') {
      setIsIncidentStateError(true);
      hasError = true;
    }

    if (incidentType === '') {
      setIsIncidentTypeError(true);
      hasError = true;
    }

    if (incidentState === '') {
      setIsIncidentStateError(true);
      hasError = true;
    }

    let message = await frameMessage(
      originalMessage,
      emailName,
      startDateTime,
      endDateTime,
      duration,
      plannedDate,
      plannedTime
    );

    if (message === '' || message.length === 0) {
      setIsMessageError(true);
      hasError = true;
    }

    if (!extractUrlsAndValidate(message)) {
      setIsValidLink(false);
      hasError = true;
    }

    if (!hasError) {
      setIsLoading(true);
      setIsStartDateTimeError(false);
      setIsEndDateTimeError(false);
      setIsIncidentTypeError(false);
      setIsIncidentStateError(false);
      setIsEndTimeLessError(false);
      setIsMessageError(false);
      setIsValidLink(true);

      const incident: updateRequest = {
        incidentId: incidentId,
        product: selectedProduct,
        service: selectedService,
        type: incidentType,
        incidentState: incidentState,
        startTimeInUTC: startDateTime,
        isFalseAlarm: isFalseAlarm,
        ...(endDateTime !== null && { endTimeInUTC: endDateTime }),
        ...(message !== '' && { message: message }),
      };

      // send API request to update incident and handel success and failures using toasts
      try {
        let updateIncidentResponse = await toast.promise(
          updateIncident(incident),
          {
            pending:
              incidentState === 'Updating'
                ? MANAGE_FORM_CONSTANTS.UPDATE_INCIDENT_PROMISE_MESSAGE
                : MANAGE_FORM_CONSTANTS.RESOLVE_INCIDENT_PROMISE_MESSAGE,
          }
        );
        if (updateIncidentResponse.status == 200) {
          toast.success(
            updateIncidentResponse?.message ?? incidentState === 'Updating'
              ? MANAGE_FORM_CONSTANTS.UPDATE_INCIDENT_SUCCESS_MESSAGE
              : MANAGE_FORM_CONSTANTS.RESOLVE_INCIDENT_SUCCESS_MESSAGE
          );
          logEvent(
            incidentState === 'Updating' ? 'UPDATE_INCIDENT' : 'RESOLVE_INCIDENT',
            'USER_ACTIVITY',
            {
              incidentID: incident.incidentId,
              service: incident.service,
              product: incident.product,
              incidentType: incident.type,
              actions: 'Update',
              resource: 'Incidents'
            }
          );
          setTimeout(() => {
            backToHome();
          }, 5000);
        } else {
          toast.error(
            updateIncidentResponse?.data ?? updateIncidentResponse.data
          );
          logEvent(
            incidentState === 'Updating' ? 'UPDATE_INCIDENT' : 'RESOLVE_INCIDENT',
            'EXCEPTION',
            {
              incidentID: incident.incidentId,
              service: incident.service,
              product: incident.product,
              incidentType: incident.type,
              actions: 'Update',
              resource: 'Incidents',
              error: updateIncidentResponse?.data ?? updateIncidentResponse.data
            }
          );
        }
      } catch (error) {
        toast.error(String(error));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const initiateCancel = () => {
    Swal.fire({
      title: `<h4>${MANAGE_FORM_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html: `<p>${MANAGE_FORM_CONSTANTS.ALERT_CONFIRM_CLOSE}<br/>`,
      showCancelButton: true,
      confirmButtonText: MANAGE_FORM_CONSTANTS.CONFIRM_CLOSE_CANCEL_TEXT,
      cancelButtonText: MANAGE_FORM_CONSTANTS.CONFIRM_CLOSE_DISCARD_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          navigate('/' + parentURL);
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const isFormValid = () => {
    // Check for presence of mandatory values
    const hasMandatoryValues =
      incidentId &&
      selectedProduct &&
      selectedService &&
      incidentType &&
      incidentState &&
      startDateTime;

    return hasMandatoryValues;
  };

  const calculateDuration = (startTime: string, endTime: string) => {
    let startDate = new Date(startTime);
    let endDate = new Date(endTime);
    let duration = endDate.getTime() - startDate.getTime();

    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    let durationString = minutes + ' minutes';
    if (hours > 0) {
      const hourString = hours == 1 ? ' hour ' : ' hours ';
      const minuteString = minutes == 1 ? ' minute' : ' minutes';
      durationString =
        hours + hourString + (minutes > 0 ? minutes + minuteString : '');
    }
    return durationString;
  };

  useEffect(() => {
    const getMessage = async () => {
      if (
        selectedProduct != '' &&
        selectedService != '' &&
        incidentType != '' &&
        incidentState != ''
      ) {
        let incidentTypeValue = incidentType;

        if (
          incidentTypeValue !== originalType &&
          incidentState !== 'Resolved'
        ) {
          if (
            incidentTypeValue === 'Outage' &&
            originalType === 'PartialOutage'
          ) {
            incidentTypeValue = 'TransitionOutage';
          }
          if (
            incidentTypeValue === 'PartialOutage' &&
            originalType === 'Outage'
          ) {
            incidentTypeValue = 'TransitionPartialOutage';
          }
        }

        if (isFalseAlarm) {
          incidentTypeValue = 'FalseAlarm';
        }


        const messageType = details[incidentState][incidentTypeValue];
        const messageResponse = await fetchServiceMessage(
          selectedProduct,
          selectedService,
          messageType
        );

        if (messageResponse.status !== 200) {
          toast.error('Failed to fetch message template');
          logEvent('GET_MESSAGE', 'EXCEPTION', {
            product: selectedProduct,
            service: selectedService,
            incidentType: messageType,
            actions: 'Retrieve',
            resource: 'Services',
            error: messageResponse?.data
              ? `"${messageResponse?.data}"`
              : 'Failed to fetch message template',
          });
        }
        
        let emailNameValue = messageResponse.data.emailName;
        setEmailName(() => emailNameValue);
        setOriginalMessage(() => messageResponse.data.template);

      }
    };
    getMessage();
  }, [
    selectedProduct,
    selectedService,
    incidentType,
    incidentState,
    isFalseAlarm,
  ]);

  useEffect(() => {
    if (startDateTime !== null && endDateTime !== null) {
      let durationValue = calculateDuration(startDateTime, endDateTime);
      setDuration(() => durationValue);
    }

    if (startDateTime !== null) {
      const startTimeDetails = startDateTime.split(' ');
      const plannedDate = startTimeDetails[0];
      const plannedTime = startTimeDetails[1];
      setPlannedDate(() => plannedDate);
      setPlannedTime(() => plannedTime);
    }
  }, [startDateTime, endDateTime]);

  useEffect(() => {
    if (isUser()) {
      navigate('/viewer')
    }
  }, []);

  return (
    <div>
      <h1 className='heading'>Manage Incident</h1>

      <div className='create-incident-card'>
        <IncidentIdInput
          isError={false}
          value={incidentDetails.incidentId}
          isDisabled={true}
        />
        <IncidentIdInput
          isError={false}
          value={serviceDetails.name}
          isDisabled={true}
          label={'Affected Service'}
        />
        <div>
          {incidentType === 'PlannedMaintenance' ? (
            <DropDownInput
              type='INCIDENT_TYPE_RESOLVED'
              onSelect={undefined}
              isError={false}
              value={incidentDetails.type}
              isDisabled={true}
            />
          ) : (
            <DropDownInput
              type='INCIDENT_TYPE_UPDATE'
              onSelect={handleDropDownSelect}
              isError={isIncidentTypeError}
              value={incidentType}
              isDisabled={false}
            />
          )}
        </div>
        <DropDownInput
          type='INCIDENT_STATE_UPDATE'
          onSelect={handleDropDownSelect}
          isError={isIncidentStateError}
          value={incidentDetails.incidentState}
          isDisabled={false}
        />
        <StartDateTimeInput
          onDateTimeChange={handleStartDateTimeChange}
          isError={isStartDateTimeError}
          value={incidentDetails.startTimeInUTC}
          isDisabled={false}
        />
        <EndDateTimeInput
          onDateTimeChange={handleEndDateTimeChange}
          isError={isEndDateTimeError}
          isEndTimeLessError={isEndTimeLessError}
          value={incidentDetails.endTimeInUTC}
          isDisabled={false}
        />
        <RadioInput
          onFalseAlarmChange={handleIsFalseAlarmChange}
          isDisabled={false}
          value={isFalseAlarm}
        />
        <div style={{ marginBottom: '-15px' }}>
          <MessageBox
            value={originalMessage}
            previewMessage={frameMessage(
              originalMessage,
              emailName,
              startDateTime,
              endDateTime,
              duration,
              plannedDate,
              plannedTime
            )}
            onMessageChange={handleMessageChange}
            macroVariables={UPDATE_INCIDENT_MACRO_VARIBALES}
            isError={isMessageError}
            isValidLink={isValidLink}
          />
        </div>
      </div>
      <div
        className='d-flex justify-content-center flex-wrap'
        style={{ marginTop: '40px' }}
      >
        <Button
          variant='tertiary'
          style={{ marginRight: '10px' }}
          onClick={initiateCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant='secondary'
          onClick={handleTimelineShow}
          style={{ marginRight: '10px' }}
          disabled={isLoading}
        >
          Message Timeline
        </Button>
        <Button
          variant='primary'
          onClick={handleUpdate}
          disabled={!isFormValid() || isLoading}
          className='update-button'
        >
          <i
            className='modus-icons material-icons left-icon'
            aria-hidden='true'
          >
            save_disk
          </i>
          Update
        </Button>
        <Modal
          show={showMessageTimeline}
          onHide={handleTimelineClose}
          centered
          size='lg'
        >
          <Modal.Header
            closeButton
            placeholder=''
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          >
            <Modal.Title>Message Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body className='manage-timeline-modal'>
            <MessageTimeline incidentId={incidentDetails.incidentId} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-secondary' onClick={handleTimelineClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <br></br>
    </div>
  );
}

export default ManageIncidents;
