import { Button, Col, Form } from "@trimbleinc/modus-react-bootstrap";
import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getConfig, modifyConfig } from "services/configuration.service";
import { clearSessionAndRedirect } from "utils/Error.utils";
import logEvent from "utils/TrimbleInsightsLogger.utils";
import { NOTIFICATION_CONFIG_CONSTANTS } from "./NotificationConfiguration.constants";
import Loading from "components/Common/Loading";


function NotificationConfiguration() {

    const { register, handleSubmit, formState, formState: { errors }, watch, reset, setValue } = useForm({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [notificationConfiguration, setNotificationConfiguration] = useState<any>();
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const fetchNotificationConfiguration = async () => {
        try {
            setIsLoaded(false);
            let notifyConfig = await getConfig('notification');
            setIsLoaded(true);
            if (notifyConfig.status == 200) {
                const { sendIsHiddenNotification, sendIncidentTypeNotification, allowTransitionNotification } = notifyConfig.data[0];
                setValue(NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.KEY,
                    sendIsHiddenNotification.toString());
                setValue(NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.KEY,
                    sendIncidentTypeNotification.find((item: any) => item.incidentType === "Outage")?.sendNotification.toString());
                setValue(NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.KEY,
                    sendIncidentTypeNotification.find((item: any) => item.incidentType === "PartialOutage")?.sendNotification.toString());
                setValue(NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.KEY,
                    sendIncidentTypeNotification.find((item: any) => item.incidentType === "PlannedMaintenance")?.sendNotification.toString());
                setValue(NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.KEY,
                    allowTransitionNotification.toString());
                setNotificationConfiguration({ sendIsHiddenNotification, sendIncidentTypeNotification, allowTransitionNotification });
            }
            if (notifyConfig.status == 400 || notifyConfig.status == 500) {
                toast.error(notifyConfig?.data ?? "Failed to fetch notification config")
                logEvent('GET_NOTIFY_CONFIG', 'EXCEPTION', {
                    actions: 'Retrieve',
                    resource: 'Config',
                    error: notifyConfig?.data ? `"${notifyConfig?.data}"` : 'Failed to fetch notification config'
                });
            }
            if (notifyConfig.status == 401) {
                toast.error("Unauthorized to access users")
                clearSessionAndRedirect()
            }
        }
        catch (error) {
            toast.error("Something went wrong")
        }
    };

    const onSubmit = async (data: any) => {
        try {
            const initial_payload = {
                sendIsHiddenNotification: data.sendIsHiddenNotification === "true",
                sendIncidentTypeNotification: [
                    { incidentType: "Outage", sendNotification: data.outage === "true" },
                    { incidentType: "PartialOutage", sendNotification: data.partial_outage === "true" },
                    { incidentType: "PlannedMaintenance", sendNotification: data.planned_maintenance === "true" }
                ],
                allowTransitionNotification: data.allowTransitionNotification === "true"
            };

            let isValid = true;
            // Ensure Outage and PartialOutage notifications are enabled if Transition is enabled
            if (initial_payload.allowTransitionNotification) {
                initial_payload.sendIncidentTypeNotification.forEach((notification: any) => {
                    if ((notification.incidentType === "Outage" && !notification.sendNotification) ||
                        (notification.incidentType === "PartialOutage" && !notification.sendNotification)) {
                        isValid = false;
                    }
                });
            }

            // Ensure Transition is disabled if either Outage or PartialOutage notifications are disabled
            initial_payload.sendIncidentTypeNotification.forEach((notification: any) => {
                if ((notification.incidentType === "Outage" && !notification.sendNotification) ||
                    (notification.incidentType === "PartialOutage" && !notification.sendNotification)) {
                    if (initial_payload.allowTransitionNotification) {
                        isValid = false;
                    }
                }
            });

            if (!isValid) {
                toast.error("Transition can only be allowed if Outage and PartialOutage notifications are enabled.");
            } else {
                const payload: any = {};
                if (initial_payload.sendIsHiddenNotification !== notificationConfiguration.sendIsHiddenNotification) {
                    payload.sendIsHiddenNotification = initial_payload.sendIsHiddenNotification;
                }

                const sendIncidentTypeNotification = initial_payload.sendIncidentTypeNotification.filter((item: any) => {
                    const currentItem = notificationConfiguration.sendIncidentTypeNotification.find((configItem: any) => configItem.incidentType === item.incidentType);
                    return currentItem && currentItem.sendNotification !== item.sendNotification;
                });

                if (sendIncidentTypeNotification.length > 0) {
                    payload.sendIncidentTypeNotification = sendIncidentTypeNotification;
                }
                if (initial_payload.allowTransitionNotification !== notificationConfiguration.allowTransitionNotification) {
                    payload.allowTransitionNotification = initial_payload.allowTransitionNotification;
                }

                setIsLoaded(false);
                let updateResponse = await modifyConfig('notification', payload);
                setIsLoaded(true);
                if (updateResponse.status === 200) {
                    toast.success(updateResponse?.message ?? NOTIFICATION_CONFIG_CONSTANTS.SUCCESS_MESSAGE);
                    fetchNotificationConfiguration();
                    logEvent('MODIFY_NOTIFY_CONFIG', 'USER_ACTIVITY', {
                        actions: 'Update',
                        resource: 'Config'
                    });
                }
                if (updateResponse.status == 409 || updateResponse.status == 400 || updateResponse.status == 500) {
                    toast.error(updateResponse?.data ?? NOTIFICATION_CONFIG_CONSTANTS.FAILURE_MESSAGE);
                    logEvent('MODIFY_NOTIFY_CONFIG', 'EXCEPTION', {
                        actions: 'Update',
                        resource: 'Config',
                        error: updateResponse?.data ? `"${updateResponse?.data}"` : NOTIFICATION_CONFIG_CONSTANTS.FAILURE_MESSAGE
                    });
                }
                if (updateResponse.status == 401) {
                    toast.error(updateResponse?.data ?? "Unauthorized to access configurations")
                    clearSessionAndRedirect()
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    const watchedValues = watch();
    useEffect(() => {
        if (notificationConfiguration) {
            const mappedConfig = {
                sendIsHiddenNotification: notificationConfiguration.sendIsHiddenNotification.toString(),
                outage: notificationConfiguration.sendIncidentTypeNotification
                    .find((item: any) => item.incidentType === "Outage")?.sendNotification.toString(),
                partial_outage: notificationConfiguration.sendIncidentTypeNotification
                    .find((item: any) => item.incidentType === "PartialOutage")?.sendNotification.toString(),
                planned_maintenance: notificationConfiguration.sendIncidentTypeNotification
                    .find((item: any) => item.incidentType === "PlannedMaintenance")?.sendNotification.toString(),
                allowTransitionNotification: notificationConfiguration.allowTransitionNotification.toString()
            };
            const hasChanges = JSON.stringify(watchedValues) !== JSON.stringify(mappedConfig);
            setIsSaveButtonEnabled(hasChanges); // Enable Save button if form values are different from initial values
        }
    }, [watchedValues, notificationConfiguration]);

    useEffect(() => {
        if (Object.keys(formState.errors)?.length == 0) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formState])

    useEffect(() => {
        fetchNotificationConfiguration();
    }, []);

    return (
        <>
            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                {!isLoaded ?
                    (<div className="loading-overlay">
                        <Loading /></div>) :
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.LABEL}
                                </Form.Label>
                                <fieldset>
                                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                                        <Form.Check
                                            type="radio"
                                            label="True"
                                            id="hidden-true"
                                            value="true"
                                            custom
                                            className="mr-3"
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.ERROR_TEXT },
                                            })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="False"
                                            id="hidden-false"
                                            value="false"
                                            custom
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.ERROR_TEXT },
                                            })}
                                        />
                                    </Form.Group>
                                    {errors?.[NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.KEY]?.message && (
                                        <Form.Control.Feedback type="invalid" className="field-error-message">
                                            {errors[NOTIFICATION_CONFIG_CONSTANTS.IS_HIDDEN.KEY]?.message?.toString()}
                                        </Form.Control.Feedback>
                                    )}
                                </fieldset>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.LABEL}
                                </Form.Label>
                                <fieldset>
                                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                                        <Form.Check
                                            type="radio"
                                            label="True"
                                            id="transition-true"
                                            value="true"
                                            custom
                                            className="mr-3"
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.ERROR_TEXT },
                                            })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="False"
                                            id="transition-false"
                                            value="false"
                                            custom
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.ERROR_TEXT },
                                            })}
                                        />
                                    </Form.Group>
                                    {errors?.[NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.KEY]?.message && (
                                        <Form.Control.Feedback type="invalid" className="field-error-message">
                                            {errors[NOTIFICATION_CONFIG_CONSTANTS.IS_TRANSITION.KEY]?.message?.toString()}
                                        </Form.Control.Feedback>
                                    )}
                                </fieldset>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.LABEL}
                                </Form.Label>
                                <fieldset>
                                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                                        <Form.Check
                                            type="radio"
                                            label="True"
                                            id="outage-true"
                                            value="true"
                                            custom
                                            className="mr-3"
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.ERROR_TEXT },
                                            })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="False"
                                            id="outage-false"
                                            value="false"
                                            custom
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.ERROR_TEXT },
                                            })}
                                        />
                                    </Form.Group>
                                    {errors?.[NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.KEY]?.message && (
                                        <Form.Control.Feedback type="invalid" className="field-error-message">
                                            {errors[NOTIFICATION_CONFIG_CONSTANTS.OUTAGE.KEY]?.message?.toString()}
                                        </Form.Control.Feedback>
                                    )}
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.LABEL}
                                </Form.Label>
                                <fieldset>
                                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                                        <Form.Check
                                            type="radio"
                                            label="True"
                                            id="parOutage-true"
                                            value="true"
                                            custom
                                            className="mr-3"
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.ERROR_TEXT },
                                            })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="False"
                                            id="parOutage-false"
                                            value="false"
                                            custom
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.ERROR_TEXT },
                                            })}
                                        />
                                    </Form.Group>
                                    {errors?.[NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.KEY]?.message && (
                                        <Form.Control.Feedback type="invalid" className="field-error-message">
                                            {errors[NOTIFICATION_CONFIG_CONSTANTS.PARTIAL_OUTAGE.KEY]?.message?.toString()}
                                        </Form.Control.Feedback>
                                    )}
                                </fieldset>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.LABEL}
                                </Form.Label>
                                <fieldset>
                                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                                        <Form.Check
                                            type="radio"
                                            label="True"
                                            id="plaMain-true"
                                            value="true"
                                            custom
                                            className="mr-3"
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.ERROR_TEXT },
                                            })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="False"
                                            id="plaMain-false"
                                            value="false"
                                            custom
                                            {...register(NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.KEY, {
                                                required: { value: true, message: NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.ERROR_TEXT },
                                            })}
                                        />
                                    </Form.Group>
                                    {errors?.[NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.KEY]?.message && (
                                        <Form.Control.Feedback type="invalid" className="field-error-message">
                                            {errors[NOTIFICATION_CONFIG_CONSTANTS.PLANNED_MAINTENANCE.KEY]?.message?.toString()}
                                        </Form.Control.Feedback>
                                    )}
                                </fieldset>
                            </Form.Group>
                            <Form.Group as={Col}>
                            </Form.Group>
                        </Form.Row>
                        <Button
                            variant="primary"
                            className="mr-2 float-right"
                            type="submit"
                            disabled={!isSaveButtonEnabled || !isFormValid}
                        >
                            Save
                        </Button>
                    </Form>
                }
            </div >
        </>
    );
}
export default NotificationConfiguration;