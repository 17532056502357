import React from "react";
import ReactQuill from "react-quill";
import QuillToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";

export const Editor = ({ id, value, onChange, variableMacroOptions }: any) => {
  const toolbarId = `toolbar-${id}`;
  const [state, setState] = React.useState({ value: '' });
  const handleChange = (value: any) => {
    setState({ value });
  };
  return (
    <div className="text-editor">
      <QuillToolbar
        toolbarId={toolbarId}
        variableMacroOptions={variableMacroOptions}
      />
      <ReactQuill
        theme="snow"
        value={value}
        id={id}
        onChange={onChange}
        placeholder={'Enter the message here'}
        modules={modules(toolbarId)}
        formats={formats}
        className="text-editor__quill"
      />
    </div>
  );
};

export default Editor;