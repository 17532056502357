import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { clearSessionAndRedirect } from '../utils/Error.utils';
import { refreshToken } from './auth.service';
import { getItemFromLocalStorage } from 'utils/LocalStorage.utils';
const api = axios.create();

api.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const axiosError: any = error;
        const responseData = axiosError.response;
        const originalRequest = axiosError.config;
        if (responseData?.status === 401 && !originalRequest._isRetry) {
            originalRequest._isRetry = true
            try {
                await refreshToken();
                const newAccessToken = getItemFromLocalStorage('accessToken');
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return api(originalRequest);
            } catch (refreshError) {
                clearSessionAndRedirect();
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export const fetchReq = async (
    url: string,
    options: any,
    responseType?: string
) => {
    try {
        const axiosOptions: AxiosRequestConfig = {
            method: options.method,
            url,
            data: options.body,
            headers: {
                ...(options.headers),
                ...(responseType ? {} : { 'Content-Type': 'application/json' }),
            },
        }
        if (options.responseType) {
            axiosOptions.responseType = options.responseType
        }
        const response: AxiosResponse = await api(axiosOptions);
        return response;

    } catch (error) {
        const axiosError: any = error;
        let responseData = axiosError.response;
        if (responseData?.status === 401 && axiosError.config?._isRetry) {
            clearSessionAndRedirect()
        }
        return responseData;
    }
};