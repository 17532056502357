const routerPath = {
  PRIVATE_ROUTE: {
    dashboard: '/dashboard',
    incidentManagement: '/incidents',
    serviceManagement: '/services',
    contactManagement: '/contacts',
    userManagement: '/users',
    viewer: '/viewer',
    createIncident: '/incidents/create',
    createService: '/services/create',
    createContact: '/contacts/create',
  },
};

export default routerPath;
