import IncidentIdInput from '../../../../components/Common/IncidentIdInput';
import {
  IIncidentProps,
  RESOLVED_INCIDENTS_CONSTANTS,
} from './ResolvedIncidents.constants';
import DropDownInput from '../../../../components/Common/DropDownInput';
import { Button, Modal } from '@trimbleinc/modus-react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RadioInput from '../../../../components/Common/RadioInput';
import RouterPath from 'config/routerPath';
import { useState } from 'react';
import MessageTimeline from '../MessageTimeline';

function ResolvedIncidents({
  incidentDetails,
  serviceDetails,
}: IIncidentProps) {
  const navigate = useNavigate();

  const [showMessageTimeline, setShowMessageTimeline] = useState(false);

  const handleTimelineClose = () => setShowMessageTimeline(false);
  const handleTimelineShow = () => setShowMessageTimeline(true);

  const handleClose = () => {
    //redirect to /incidents page
    navigate(RouterPath.PRIVATE_ROUTE.incidentManagement);
  };

  return (
    <div>
      <h1 className='heading'>Resolved Incident</h1>

      <div className='resolve-incident-card'>
        <IncidentIdInput
          isError={false}
          value={incidentDetails.incidentId}
          isDisabled={true}
        />
        <IncidentIdInput
          isError={false}
          value={serviceDetails.name}
          isDisabled={true}
          label={'Affected Service'}
        />
        <DropDownInput
          type='INCIDENT_TYPE_RESOLVED'
          onSelect={undefined}
          isError={false}
          value={incidentDetails.type}
          isDisabled={true}
        />
        <DropDownInput
          type='INCIDENT_STATE_RESOLVED'
          onSelect={undefined}
          isError={false}
          value={incidentDetails.incidentState}
          isDisabled={true}
        />
        <IncidentIdInput
          isError={false}
          value={incidentDetails.startTimeInUTC}
          isDisabled={true}
          label='Start Time'
        />
        <div style={{ marginBottom: '-15px' }}>
          <IncidentIdInput
            isError={false}
            value={incidentDetails.endTimeInUTC}
            isDisabled={true}
            label='End Time'
          />
          <RadioInput
            onFalseAlarmChange={undefined}
            isDisabled={true}
            value={incidentDetails.isFalseAlarm}
          />
        </div>
      </div>
      <div
        className='d-flex justify-content-center'
        style={{ marginTop: '40px' }}
      >
        <Button variant='primary' onClick={handleClose}>
          Close
        </Button>
        <Button
          variant='secondary'
          onClick={handleTimelineShow}
          style={{ marginLeft: '10px' }}
        >
          Message Timeline
        </Button>
        <Modal
          show={showMessageTimeline}
          onHide={handleTimelineClose}
          centered
          size='lg'
        >
          <Modal.Header
            closeButton
            placeholder=''
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          >
            <Modal.Title>Message Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body className='manage-timeline-modal'>
            <MessageTimeline incidentId={incidentDetails.incidentId} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-secondary' onClick={handleTimelineClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ResolvedIncidents;
