import { Form, Button, Col } from "@trimbleinc/modus-react-bootstrap";
import { ADD_USER_CONSTANTS, IAddUserProps } from "./AddUser.constants";
import { useForm } from "react-hook-form";
import {useEffect, useState } from "react";
import { addUser } from "../../../../services/userMgmt.service";
import { clearSessionAndRedirect } from "../../../../utils/Error.utils";
import { toast } from 'react-toastify';
import { isNotAdmin } from "utils/RoleCheck.utils";
import logEvent from "utils/TrimbleInsightsLogger.utils";

function AddUser( {setAddedUserEmail} : IAddUserProps) {
    const { register, handleSubmit, reset, formState, formState: { errors } } = useForm({
        defaultValues: {
            [ADD_USER_CONSTANTS.ROLE.key]: ""
        }
    });
    const [isFormValid, setIsFormValid] = useState<boolean>(false)

    const onSubmit = async (data: any) => {
        try {
            let userData = await addUser(data)
            if(userData.status == 200)
            {
                toast.success(userData?.message ?? "Email added successfully")
                setAddedUserEmail(data.userEmailId)
                logEvent('NEW_USER', 'USER_ACTIVITY', {
                  contactEmail: data.userEmailId,
                  role: data.role,
                  actions:'Create',
                  resource: 'Users'
                });
                reset()
            }
            if(userData.status == 409 || userData.status == 400 || userData.status == 500){
                toast.error(userData?.data ?? "Failed to add user")
                logEvent('NEW_USER', 'EXCEPTION', {
                  contactEmail: data.userEmailId,
                  role: data.role,
                  actions:'Create',
                  resource: 'Users',
                  error: userData?.data ?  `"${userData?.data}"` : "Failed to add user"
                });
            }
            if(userData.status == 401)
            {
                toast.error(userData?.data ??"Unauthorized to access add User page")
                clearSessionAndRedirect()
            }
        }
        catch {
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        if (Object.keys(formState.errors)?.length == 0) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formState])
    return (
      <>
        <h1 className='heading'>Portal User Management</h1>
        <p style={{textAlign:'center'}}>
          [This page is intended to grant access to the
          management portal exclusively to Trimble users]
        </p>
        <br></br>
        <div className='add-user'>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Row>
              <Col xs={12} md={7} className='mb-3'>
                <Form.Label htmlFor='inlineFormInputName2' srOnly>
                  {ADD_USER_CONSTANTS.EMAIL.label}
                </Form.Label>
                <Form.Control
                  type='input'
                  aria-label='email'
                  size='lg'
                  placeholder={ADD_USER_CONSTANTS.EMAIL.placeholder}
                  disabled={isNotAdmin()}
                  {...register(ADD_USER_CONSTANTS.EMAIL.key, {
                    required: {
                      value: true,
                      message: ADD_USER_CONSTANTS.EMAIL.validationMessage,
                    },
                    maxLength: {
                      value: 250,
                      message: ADD_USER_CONSTANTS.EMAIL.error_length,
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@trimble\.com$/i,
                      message: ADD_USER_CONSTANTS.EMAIL.validationMessage,
                    },
                  })}
                ></Form.Control>
                {errors?.[ADD_USER_CONSTANTS.EMAIL.key]?.message && (
                  <Form.Control.Feedback
                    type='invalid'
                    className='field-error-message'
                  >
                    {errors[ADD_USER_CONSTANTS.EMAIL.key]?.message?.toString()}
                  </Form.Control.Feedback>
                )}
              </Col>

              <Col xs={12} md={3} className='mb-3'>
                <Form.Label htmlFor='inlineFormInputName2' srOnly>
                  {ADD_USER_CONSTANTS.ROLE.label}
                </Form.Label>
                <Form.Control
                  as='select'
                  size='lg'
                  custom
                  disabled={isNotAdmin()}
                  {...register(ADD_USER_CONSTANTS.ROLE.key, {
                    required: {
                      value: true,
                      message: ADD_USER_CONSTANTS.ROLE.validationMessage,
                    },
                  })}
                >
                  <option disabled value=''>
                    {ADD_USER_CONSTANTS.ROLE.drop_down_default}
                  </option>
                  {ADD_USER_CONSTANTS.ROLE_LIST.map((value) => (
                    <option value={value.code} key={value.code}>
                      {value.name}
                    </option>
                  ))}
                </Form.Control>
                {errors?.[ADD_USER_CONSTANTS.ROLE.key]?.message && (
                  <Form.Control.Feedback
                    type='invalid'
                    className='field-error-message'
                  >
                    {errors[ADD_USER_CONSTANTS.ROLE.key]?.message?.toString()}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col xs={12} md={2} className='mb-3'>
                <Button
                  variant='primary'
                  className='mb-2'
                  type='submit'
                  size='lg'
                  disabled={!isFormValid || isNotAdmin()}
                >
                  {ADD_USER_CONSTANTS.ADD_USER.label}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </>
    );
}

export default AddUser;