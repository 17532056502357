import { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import WithAuth, { GetAccessToken, Logout } from '../../Auth/WithAuth';
import UserManagement from '../../../features/UserManagement';
import IncidentManagement from '../../../features/IncidentManagement';
import ServiceManagement from '../../../features/ServiceManagement';
import Layout from '../Layout';
import CanAccess from '../../Auth/CanAccess';
import { PROJECT_CONSTANTS } from '../../../constants/Project.constants';
import ViewerPage from '../../../features/ViewerPage';
import Dashboard from '../../../features/Dashboard';
import CreateIncidentForm from './../../../features/IncidentManagement/components/CreateIncident/components/CreateIncidentForm/CreateIncidentForm';
import ViewIncidents from '../../../features/ViewIncidents';
import CreateServiceForm from 'features/ServiceManagement/components/AddService/components/CreateServiceForm';
import EditService from '../TreeLayout/components/EditService/EditService';
import ContactListManagement from '../../../features/ContactListManagement';
import ErrorPage from '../ErrorPage';
import CreateExternalContact from '../../../features/ContactListManagement/components/TabContainer/components/EscalationContacts/ExternalContacts/components/ListExternalContacts/components/CreateExternalContact';
import UpdateExternalContact from '../../../features/ContactListManagement/components/TabContainer/components/EscalationContacts/ExternalContacts/components/ListExternalContacts/components/UpdateExternalContact';
import RouterPath from 'config/routerPath';

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/redirect" element={<GetAccessToken />} />
          <Route path="/logout" element={<Logout />} />
          <Route element={<WithAuth />}>
            <Route element={<Layout />}>
              <Route
                path={RouterPath.PRIVATE_ROUTE.dashboard}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                      PROJECT_CONSTANTS.USER_ROLES.user,
                    ]}
                  />
                }
              >
                <Route index element={<Dashboard />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.incidentManagement}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                      PROJECT_CONSTANTS.USER_ROLES.user,
                    ]}
                  />
                }
              >
                <Route index element={<IncidentManagement />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.serviceManagement}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                      PROJECT_CONSTANTS.USER_ROLES.user,
                    ]}
                  />
                }
              >
                <Route index element={<ServiceManagement />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.userManagement}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                      PROJECT_CONSTANTS.USER_ROLES.user
                    ]}
                  />
                }
              >
                <Route index element={<UserManagement />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.viewer}
                element={
                  <CanAccess
                    allowedRoles={[PROJECT_CONSTANTS.USER_ROLES.viewer]}
                  />
                }
              >
                <Route index element={<ViewerPage />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.createIncident}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                    ]}
                  />
                }
              >
                <Route index element={<CreateIncidentForm />} />
              </Route>
              <Route
                path={`${RouterPath.PRIVATE_ROUTE.incidentManagement}/:id`}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                      PROJECT_CONSTANTS.USER_ROLES.user,
                    ]}
                  />
                }
              >
                <Route index element={<ViewIncidents />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.createService}
                element={
                  <CanAccess
                    allowedRoles={[PROJECT_CONSTANTS.USER_ROLES.admin]}
                  />
                }
              >
                <Route index element={<CreateServiceForm />} />
              </Route>
              <Route
                path={`${RouterPath.PRIVATE_ROUTE.serviceManagement}/:id`}
                element={
                  <CanAccess
                    allowedRoles={[PROJECT_CONSTANTS.USER_ROLES.admin]}
                  />
                }
              >
                <Route index element={<EditService />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.contactManagement}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                      PROJECT_CONSTANTS.USER_ROLES.superUser,
                      PROJECT_CONSTANTS.USER_ROLES.user,
                    ]}
                  />
                }
              >
                <Route index element={<ContactListManagement />} />
              </Route>
              <Route
                path={RouterPath.PRIVATE_ROUTE.createContact}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin,
                    ]}
                  />
                }
              >
                <Route index element={<CreateExternalContact />} />
              </Route>
              <Route
                path={`${RouterPath.PRIVATE_ROUTE.contactManagement}/:id`}
                element={
                  <CanAccess
                    allowedRoles={[
                      PROJECT_CONSTANTS.USER_ROLES.admin
                    ]}
                  />
                }
              >
                <Route index element={<UpdateExternalContact />} />
              </Route>

              <Route path="/" element={<Navigate to={RouterPath.PRIVATE_ROUTE.dashboard} />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
