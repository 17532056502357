import { Col, Form, Row } from '@trimbleinc/modus-react-bootstrap';
import {
  IIncidentIdInputProps,
  INCIDENTS_ID_CONSTANTS,
} from './IncidentIdInput.constants';

function IncidentIdInput({
  onIdChange,
  isError,
  value,
  isDisabled,
  label,
}: IIncidentIdInputProps) {
  return (
    <Form>
      <Row className="form-row">
        <Col md={6}>
          <h4 className="form-label" style={{marginTop:"6px"}}>
            {label ? label : INCIDENTS_ID_CONSTANTS.input}
          </h4>
        </Col>
        <Col md={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              as="input"
              placeholder={INCIDENTS_ID_CONSTANTS.placeholder}
              className={`input-border ${isError ? 'is-invalid' : ''}`}
              style={
                isDisabled
                  ? {
                      background: '#dadce7',
                      fontWeight: 'bolder',
                      opacity: '1.0',
                    }
                  : {}
              }
              onChange={(e) => onIdChange && onIdChange(e.target.value)}
              value={value}
              disabled={isDisabled}
            />
            {isError && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {INCIDENTS_ID_CONSTANTS.error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

export default IncidentIdInput;
