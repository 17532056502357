import { Service } from '../ServiceFilter/ServiceFilterConstants';
import { PreviousData } from '../UpdateExternalContact/UpdateExternalContactConstants';

export const EXTERNAL_CONTACT_UTILITY_CONSTANTS = {
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_CLOSE: 'Do you really want to discard creation? ',
  CONFIRM_CLOSE_CANCEL_TEXT: 'Yes',
  CONFIRM_CLOSE_DISCARD_TEXT: 'No',
};

export interface IExternalContactUtilityProps {
  data: Service[];
  markEntry?: PreviousData;
}

export interface Node {
  nodeId: number;
  label: string;
  statuscastId: number;
  statuscastGroupId: number;
  isMainComponent: boolean;
  isHidden: boolean;
  children?: Node[];
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
}

export interface Selected {
  label: string;
  nodeId: number;
}

export interface apiRequestBody {
  emails: string[];
  statuscastServiceId: number[];
  statuscastGroupId: number[];
  displayName: string[];
}
