import { getItemFromLocalStorage } from '../utils/LocalStorage.utils';
import { fetchReq } from './http.service';
import serviceUrls from './serviceUrls';

const { groupsURL } = serviceUrls;

export const createEscalationContact = async (data: any) => {
  const url = `${groupsURL}/api/escalation`;
  return await fetchReq(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
};

export const fetchEscalationContact = async (
  pageSize: number,
  pageNumber: number,
  level: number,
  email: string
) => {
  let url = `${groupsURL}/api/escalation?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  if(level)
    url = `${url}&escalationLevel=${level}`;

  if (email !== '') {
    url = `${url}&email=${email}`;
  }
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const deleteEscalationContact = async (email: string) => {
  const url = `${groupsURL}/api/escalation?email=${email}`;
  return await fetchReq(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const updateEscalationContact = async (data: any) => {
  const url = `${groupsURL}/api/escalation`;
  return await fetchReq(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const fetchExternalContact = async (
  pageSize: number,
  pageNumber: number,
  statuscastServiceIdQuery: string,
  email: string
) => {
  let url = `${groupsURL}/api/subscriber?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  let headers: { [key: string]: string } = {
    Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
  };

  if (statuscastServiceIdQuery != '0')
    headers = {
      ...headers,
      statuscastServiceId: statuscastServiceIdQuery,
    };

  if (email !== '') {
    url = `${url}&email=${email}`;
  }
  return await fetchReq(url, {
    method: 'GET',
    headers: headers,
  });
};

export const deleteExternalContact = async (email: string) => {
  const url = `${groupsURL}/api/subscriber?email=${email}`;
  return await fetchReq(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const upsertExternalContact = async (data: any, isReplace: boolean) => {
  const url = `${groupsURL}/api/subscriber?isReplace=${isReplace}&isUI=true`;
  return await fetchReq(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
};

export const fetchSpecificExternalContact = async (
  statusCastServiceId: string
) => {
  let url = `${groupsURL}/api/subscriber?subscriptionId=${statusCastServiceId}`;

  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const downloadExternalContact = async (
  data: any,
) => {
  let url = `${groupsURL}/api/subscriber`;

  return await fetchReq(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
};

export const pollDownloadStatus = async (
  email: string
) => {
  let url = `${groupsURL}/api/pollExportStatus?loggedInUserEmail=${email}`;
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const downloadFile = async (
  fileName: string,
) => {
  let url = `${groupsURL}/api/subscriber?downloadCsv=true&fileName=${fileName}`;
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const restoreService = async (orchestrationId: string) => {
  const url = `${groupsURL}/api/subscriber?orchestrationId=${orchestrationId}`;
  return await fetchReq(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};