import {
  Alert,
  AlertDismissible,
  Button,
  OverlayTrigger,
  Tooltip,
} from '@trimbleinc/modus-react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListExternalContacts from './components/ListExternalContacts';
import { useEffect, useState } from 'react';
import { fetchAllServices } from 'services/serviceManagement.service';
import { toast } from 'react-toastify';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import Loading from 'components/Common/Loading';
import {
  ESCALATION_CONTACTS_CONSTANTS,
  Service,
} from './ExternalContactsConstants';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import {
  downloadExternalContact,
  downloadFile,
  pollDownloadStatus,
} from 'services/groupManagement.service';
import { getItemFromLocalStorage } from 'utils/LocalStorage.utils';

interface DownloadFileRequests {
  loggedInUserEmail: string;
  statusCastServiceId?: string;
}

function ExternalContact() {
  const [services, setServices] = useState<Service[]>([]);
  const [isLoadedService, setIsLoadedService] = useState(false);
  const [serviceIds, setServiceIds] = useState<number[]>([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [downloadCompleted, setDownloadCompleted] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouterPath.PRIVATE_ROUTE.createContact, {
      state: { data: services },
    });
  };

  const fetchService = async () => {
    try {
      const serviceResponse = await fetchAllServices();
      if (serviceResponse.status === 200) {
        setIsLoadedService(true);
        setServices(serviceResponse.data);
      } else if (serviceResponse.status == 401) {
        toast.error('Unauthorized to access services');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch Services');
        logEvent('GET_ALL_SERVICES', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Services',
          error: serviceResponse?.data
            ? `"${serviceResponse?.data}"`
            : 'Failed to fetch services',
        });
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleContactDump = () => {
    window.open(ESCALATION_CONTACTS_CONSTANTS.CONTACTS_DUMP_SHEET_URL);
  };

  const handleDownload = async () => {
    setDownloadInProgress(true);
    const userEmail = JSON.parse(getItemFromLocalStorage('auth')!).email;
    let requestBody: DownloadFileRequests = {
      loggedInUserEmail: userEmail,
    };

    if (serviceIds.join(',') !== '0') {
      requestBody.statusCastServiceId = serviceIds.join(',');
    }

    const downloadContacts = await downloadExternalContact(requestBody);
    if (downloadContacts.status === 200) {
      setDownloadInProgress(true);
      setRenderComponent(!renderComponent);

    } else if (downloadContacts.status == 401) {
      toast.error('Unauthorized to access services');
      clearSessionAndRedirect();
    } else {
      setDownloadInProgress(false);
      toast.error('Failed to download contacts');
      logEvent('DOWNLOAD_EXTERNAL_CONTACTS', 'EXCEPTION', {
        actions: 'Download',
        resource: 'External_Contacts',
        error: downloadContacts?.data
          ? `"${downloadContacts?.data}"`
          : 'Failed to download contacts',
      });
    }
  };

  useEffect(() => {
    fetchService();
  }, []);

  const downloadFileHelper = async (fileName: string) => {
    const downloadurlFetch = await downloadFile(fileName);
    if (downloadurlFetch.status === 200) {
      const downloadUrl = downloadurlFetch.data;

      if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        a.target = '_blank';
        a.click();
      } else {
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = downloadUrl;
        tempLink.setAttribute('download', fileName);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(tempLink);
        }, 200);
      }
    }
  };

  useEffect(() => {
    const userEmail = JSON.parse(getItemFromLocalStorage('auth')!).email;
    const interval = setInterval(async () => {
      const pollStatus = await pollDownloadStatus(userEmail);
      if (pollStatus.data.length > 0) {
        if (pollStatus.data[0].exportStatus === 'InProgress') {
          setDownloadInProgress(true);
        } else if (pollStatus.data[0].exportStatus === 'Success') {
          const fileName = pollStatus.data[0].orchestrationId;
          await downloadFileHelper(fileName);
          setDownloadInProgress(false);
          setDownloadCompleted(true);
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [renderComponent]);
  return (
    <div>
      {isLoadedService ? (
        <>
          <div className='d-flex justify-contents-center create-ext-button-position'>
            <div className='download-btn'>
              <OverlayTrigger
                key='overlay1'
                placement='top'
                overlay={<Tooltip id='btntooltip'>Download</Tooltip>}
              >
                <div className='d-flex export-button'>
                  <Button
                    variant='tertiary'
                    size='lg'
                    onClick={handleDownload}
                    style={{ borderRadius: '35px' }}
                    disabled={downloadInProgress}
                  >
                    <i className='modus-icons left-icon' aria-hidden='true'>
                      unload_route_stop
                    </i>
                    Download
                  </Button>
                </div>
              </OverlayTrigger>
            </div>

            <div>
              <OverlayTrigger
                key='overlay1'
                placement='top'
                overlay={<Tooltip id='btntooltip'>Contacts Dump</Tooltip>}
              >
                <div className='d-flex export-button'>
                  <Button
                    variant='tertiary'
                    size='lg'
                    onClick={handleContactDump}
                    style={{ borderRadius: '35px' }}
                  >
                    <i
                      className='modus-icons material-icons left-icon'
                      aria-hidden='true'
                    >
                      external_link
                    </i>
                    Contacts Dump
                  </Button>
                </div>
              </OverlayTrigger>
            </div>

            <div>
              <Button
                variant='primary'
                size='lg'
                onClick={handleClick}
                style={{ borderRadius: '35px' }}
                disabled={isNotAdmin()}
              >
                <i
                  className='modus-icons material-icons left-icon'
                  aria-hidden='true'
                >
                  add
                </i>
                Add Contact
              </Button>
            </div>
          </div>
          <div>
            {downloadInProgress && (
              <Alert key='a1' variant='primary'>
                <i className='modus-icon material-icons alert-icon'>info</i>Your
                download is in progress. Please wait...
              </Alert>
            )}
            {downloadCompleted && (
              <AlertDismissible
                key='a4'
                variant='success'
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClose={() => setDownloadCompleted(false)}
              >
                <i className='material-icons alert-icon'>check_circle</i>
                Download has been completed. Please check your downloads.
              </AlertDismissible>
            )}
          </div>
          <ListExternalContacts
            services={services}
            setServiceIds={setServiceIds}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default ExternalContact;
