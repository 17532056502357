import { Col, Form, FormGroup, Row } from '@trimbleinc/modus-react-bootstrap';
import {
  RADIO_INPUT_CONSTANTS,
  IRadioInputProps,
} from './RadioInput.constants';
import { useState } from 'react';

function RadioInput({
  onFalseAlarmChange,
  value,
  isDisabled,
}: IRadioInputProps) {
  const [radioValue, setRadioValue] = useState<boolean>(value ? value : false);
  const handleRadioChange = (value: boolean) => {
    setRadioValue(value);
    if (onFalseAlarmChange) {
      onFalseAlarmChange(value);
    }
  };

  return (
    <Row className="form-row">
      <Col md={6}>
        <h4 className="form-label" style={{ marginTop: '6px' }}>
          {RADIO_INPUT_CONSTANTS.input}
        </h4>
      </Col>
      <Col md={6}>
        <FormGroup className="d-flex flex-row" style={{ marginTop: '-10px' }}>
          <Form.Check
            type="radio"
            id="radio1"
            custom
            label={RADIO_INPUT_CONSTANTS.yes}
            style={{ marginRight: '20px', padding: '0px 0px 0px 15px' }}
            onClick={() => handleRadioChange(true)}
            checked={radioValue}
            //   disabled={isDisabled}
            className={isDisabled ? 'isDisabledStyle' : 'isNotDisabledStyle'}
          />
          <Form.Check
            type="radio"
            id="radio2"
            custom
            label={RADIO_INPUT_CONSTANTS.no}
            onClick={() => handleRadioChange(false)}
            checked={!radioValue}
            //   disabled={isDisabled}
            className={isDisabled ? 'isDisabledStyle' : 'isNotDisabledStyle'}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default RadioInput;
